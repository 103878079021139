import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Select,
    TextField,
    RadioGroup,
    Radio,
    Autocomplete,
    Stack,
    IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import configServ from '../../services/config';
import Calculation from '../../utility/Calculations';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import AlertDialog from '../AlertDialog/AlertDialog';
import CardHeadMenu from '../Card/CardHeadMenu/CardHeadMenu';
import Constants from '../../utility/Constants';
import AddVoucherCustomerDetailList from '../Voucher/AddVoucherCustomerDetailList';
import AddVoucherAccountDetailList from '../Voucher/AddVoucherAccountDetailList';
import AddVoucherSupplierDetailList from '../Voucher/AddVoucherSupplierDetailList';
import AddJournalEntryDetail from './AddJournalEntryDetail';

//FUNCTION
const JournalEntryCard = ({ editData, setEditData, isChanged, setIsChanged }) => {
    //#region code
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);

    const [customers, setCustomers] = useState([]);
    const customerListInitial = [{
        id: 1,
        invoiceList: [],
        invoice_number: '',
        transaction_id: '',
        amount: ''
    }];
    const [customerList, setCustomerList] = useState(customerListInitial);
    const [customerListError, setCustomerListError] = useState([]);
    const formDataInitial = {
        generate_voucher_no: true,
        voucher_type: 'journal',
        payment_mode: 2,
        transaction_date: Calculation.GetCurrentDate(),
        remarks: null,
        is_credit: true,
        status: true,
        send_mail: false,
    }
    const [accountList, setAccountList] = useState([]);
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [voucherDetails, setVoucherDetails] = useState([]);
    const [editVoucherDetails, setEditVoucherDetails] = useState(null);
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        setReset(!reset);
    }, [formData.entity_type]);

    useEffect(() => {
        calculateTotalAmount();
    }, [customerList]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            fetchVoucherByVoucherNo(editData.voucher_no);
            setIsEdit(true);
            setIsCreate(true);
        }
    }, [editData]);

    const fetchVoucherByVoucherNo = async (voucher_no) => {
        try {
            const dataToSend = {
                "voucher_no": voucher_no
            }
            const res = await configServ.getVoucher(dataToSend);
            if (res.status === 200) {
                const data = res.data;
                setFormData((prev) => ({
                    ...prev,
                    voucher_type: data[0].voucher_type,
                    voucher_no: data[0].voucher_no,
                    payment_mode: data[0].pay_mode,
                    transaction_date: data[0].date,
                    account: data[0].account,
                    remarks: data[0].remarks,
                    entity_type: data[0].entity_type,
                    is_credit: Boolean(data[0].is_credit)
                }));
                setTotalAmount(data[0].amount);
                let custList = [];
                for (const item of data) {
                    try {
                        const invoiceList = await fetchInvoiceByCustomerID(item.entity_id);
                        const custData = {
                            id: item.voucher_list.id,
                            customer_id: item.entity_id,
                            entity_type: item.entity_type,
                            inv_id: item.voucher_list.inv_id,
                            invoice_number: item.voucher_list.receiptno,
                            transaction_id: item.voucher_list.transaction_id,
                            amount: item.voucher_list.amount,
                            image: Boolean(data[0].is_image) === true ? `data:image/${data[0].extension};base64,${data[0].image}` : null,
                            invoiceList: invoiceList,
                        };
                        custList.push(custData);
                    }
                    catch (error) {
                        console.error('Error fetching invoice list:', error);
                    }
                }
                setEditVoucherDetails(custList);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // const fetchVoucherList = async(data) => {
    //   try{
    //     for (const item of data) {
    //         const dataToSend = {
    //           id: item.id,
    //         };

    //         try {
    //           const res = await configServ.getVoucherList(dataToSend);
    //           if(res.status === 200){
    //             const data = res.data;
    //           }
    //         } 
    //         catch (error) {
    //           console.error(`Error fetching voucher list for item ${item.id}:`, error);
    //         }
    //       }
    //   }
    //   catch(e){
    //     console.log(e);
    //   }
    // }

    const fetchCustomerList = async () => {
        try {
            const result = await configServ.get_customer({
                "company_id": company_id,
            });
            setCustomers(result);
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchAccountMasterList = async (params) => {
        try {
            const dataToSend = {
                "is_super": is_super,
                "user_id": user_id,
                "company_id": company_id,
                "search_by": 'accountclassified',
                "search": params,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getAccountMasters(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setAccountList(result);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (formData.payment_mode === 1 || formData.payment_mode === 2) {
            fetchAccountMasterList(null);
        }
        else {
            fetchAccountMasterList(null);
        }
    }, [formData.payment_mode]);

    const handlePopup = () => {
        try {
            if (isCreate === true) {
                handleReset();
            }
            setIsCreate(!isCreate);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormData = (event) => {
        try {
            const { name, value } = event.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleIsCreditChange = (params) => {
        try {
            const { name, value } = params.target;
            var isCredit = true;
            if (value === 'false') {
                isCredit = false;
            }
            setFormData((prev) => ({
                ...prev,
                [name]: isCredit
            }));
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked,
        }));
    };

    const fetchInvoiceByCustomerID = async (customer_id) => {
        try {
            const dataToSend = {
                "customer_id": customer_id
            }
            const res = await configServ.getInvoiceByCustomerID(dataToSend);
            if (res.status === 200) {
                return res.data;
            }
            else {
                return null;
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleAutocompleteChange = (event, id) => {
        const value = event.target.innerText;
        if (value !== null && value !== undefined) {
            if (value.length > 0) {
                handleInputChange({ target: { name: 'fname', value } }, id);
            }
        }
    }

    const handleInvoiceChange = (value, id) => {
        if (value !== null && value !== undefined) {
            if (value) {
                handleInputChange({ target: { name: 'invoice_number', value } }, id);
            }
        }
    }

    const handleInputChange = async (event, id) => {
        try {
            const { name, value } = event.target;
            let updatedCustomer = [];
            if (value !== null && value !== undefined) {
                if (value.length > 0) {
                    if (name === 'fname') {
                        // Find the selected item from the options
                        const valueData = value.split('~')[0];
                        const selectedItem = customers.find((item) => item.fname.toLowerCase() === valueData.toLowerCase());
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            const invoiceList = await fetchInvoiceByCustomerID(selectedItem.id);
                            updatedCustomer = {
                                ...customerList.find((item) => item.id === id),
                                customer_id: selectedItem.id,
                                fname: selectedItem.fname,
                                address: selectedItem.address,
                                invoiceList: invoiceList,
                            };
                        }
                        else {
                            // If the selected item is not found, reset the properties
                            updatedCustomer = {
                                ...customerList.find((item) => item.id === id),
                                customer_id: '',
                                fname: '',
                                address: '',
                            };
                        }
                    }
                    else if (name === 'invoice_number') {
                        // Find the selected invoice item
                        const selectedInvoice = customerList.find((item) => item.id === id).invoiceList.find((item) => item.invoice_number === value);
                        // For other fields, just update the value
                        updatedCustomer = {
                            ...customerList.find((item) => item.id === id),
                            [name]: value,
                            'inv_id': selectedInvoice ? selectedInvoice.id : '',
                            'invoice_amount': selectedInvoice ? selectedInvoice.total_amount : '',
                        };
                    }
                    else {
                        // For other fields, just update the value
                        updatedCustomer = {
                            ...customerList.find((item) => item.id === id),
                            [name]: value,
                        };
                    }
                    // Update the customerList state with the updated item
                    const updatedCustomerList = customerList.map((item) =>
                        item.id === id ? updatedCustomer : item
                    );
                    setCustomerList(updatedCustomerList);
                }
                else {
                    // For other fields, just update the value
                    updatedCustomer = {
                        ...customerList.find((item) => item.id === id),
                        [name]: value,
                    };
                }
                // Update the customerList state with the updated item
                const updatedCustomerList = customerList.map((item) =>
                    item.id === id ? updatedCustomer : item
                );
                setCustomerList(updatedCustomerList);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;
        customerList.forEach(item => {
            // Parse the total_amount string to a float value
            const total_amount = parseFloat(item.amount);
            // Check if the total_amount is a valid number
            if (!isNaN(total_amount)) {
                // Add the total_amount to the total
                totalAmount += total_amount;
            }
        });
        setTotalAmount(totalAmount.toFixed(2));
    };

    // Function to add a new customer to the list
    const handleAddCustomer = () => {
        setCustomerList([
            ...customerList,
            { id: customerList.length + 1 },
        ]);
    };

    // Function to delete an item from the list based on its ID
    const handleDeleteCustomer = (id) => {
        const updatedCustomerList = customerList.filter((item) => item.id !== id);
        setCustomerList(updatedCustomerList);
    };

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.voucher_type) {
                errors.voucher_type = 'Voucher type is required.'
            }
            if (formData.generate_voucher_no === false) {
                if (!formData.voucher_no) {
                    errors.voucher_no = 'Voucher No is required.'
                }
            }
            if (!formData.payment_mode) {
                errors.payment_mode = 'Payment mode is required.'
            }
            if (!formData.account) {
                errors.account = 'Account is required.'
            }
            if (!formData.transaction_date) {
                errors.transaction_date = 'Date is required.'
            }
            setValidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const validateCustomerList = async () => {
        const errors = customerList.map(item => ({
            customer_id: !item.customer_id ? 'Customer Name is required.' : '',
            amount: !item.amount ? 'Amount is required.' : '',
            // Add additional validations for other item fields as needed...
        }));

        setCustomerListError(errors);
        // Check if there are any errors
        const hasErrors = errors.some(error => Object.values(error).some(val => val !== ''));

        return !hasErrors;
    };

    const handleCustomerList = async () => {
        try {
            const payRecpCustomers = voucherDetails.map((item) => ({
                customer_id: item.entity_id,
                entity_type: item.entity_type,
                fname: item.fname,
                inv_id: item.inv_id,
                invoice_number: item.invoice_number,
                invoice_amount: item.invoice_amount,
                transaction_id: item.transaction_id,
                transaction_date: formData.transaction_date,
                amount: item.amount,
                image: item.image,
            }));
            return payRecpCustomers;
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (event) => {
        try {
            setLoading(true);
            dispatch(triggerLoader());
            event.preventDefault();

            const validateCard = await validate();
            if (validateCard === false || error === true) {
                return false;
            }
            formData.total_amount = totalAmount;
            formData.company_id = company_id;
            formData.user_id = user_id;
            formData.customers = await handleCustomerList();
            if (!isEdit) {
                const res = await configServ.createJournalVouchers(formData);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', res.message || 'Voucher created successfully.', 'success');
                    handleReset();
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to create voucher.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to create voucher.", 'error');
                }
            }
            else {
                const res = await configServ.updateJournalVouchers(formData);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', res.message || 'Voucher updated successfully.', 'success');
                    handleReset();
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update voucher.", 'error');
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData(formDataInitial);
            setValidationErrors({});
            setCustomerList(customerListInitial);
            setCustomerListError({});
            setEditData({});
            setIsEdit(false);
            setReset(!reset);
            setVoucherDetails([]);
            setEditVoucherDetails(null);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleVoucherDetail = (params) => {
        try {
            setVoucherDetails(params);
            // Calculate the total amount using reduce
            const totalAmount = params.reduce((acc, item) => {
                return acc + Number(item.amount);
            }, 0);
            setTotalAmount(totalAmount);
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <CardHeadMenu
                prefix={"Inv"}
                suffix={"Order"}
                title={"JOURNAL VOUCHER"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {isCreate === true && (
                <>
                    <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: 0.1 }}
                    >
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                sx={{ p: 0, }}
                                spacing={1}
                            >
                                <Grid item xs={6} md={6} p={0}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        checked={Boolean(formData.generate_voucher_no)}
                                        name="generate_voucher_no"
                                        onChange={handleChangeCheckbox}
                                        label="Auto Generate"
                                        sx={{ fontWeight: "bold", m: 0, p: 0 }}
                                    />
                                </Grid>
                                <Grid container item xs={6} md={6} alignItems={'center'} justifyContent={'flex-end'}>
                                    <Stack direction={'row'} spacing={1}>
                                        <IconButton
                                            onClick={handleReset}
                                            sx={{
                                                borderRadius: 2,
                                                px: 2, // Horizontal padding
                                                py: 1, // Vertical padding
                                                backgroundColor: 'secondary.main', // Use theme secondary color
                                                color: 'white', // Icon color
                                                '&:hover': {
                                                    backgroundColor: 'secondary.dark', // Darker shade on hover
                                                },
                                                ml: 1, // Margin left to separate from other elements
                                            }}
                                            title="Reset"
                                        >
                                            <RotateLeftIcon sx={{ fontSize: 16 }} />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={handleSubmit}
                                            sx={{
                                                borderRadius: 2,
                                                px: 2, // Horizontal padding
                                                py: 1, // Vertical padding
                                                backgroundColor: isEdit ? 'success.main' : 'primary.main', // Use theme primary color
                                                color: 'white', // Icon color
                                                '&:hover': {
                                                    backgroundColor: isEdit ? 'success.dark' : 'primary.dark', // Darker shade on hover
                                                },
                                            }}
                                            title={isEdit ? "Update" : "Save"}
                                        >
                                            {isEdit ? <UpdateIcon sx={{ fontSize: 16 }} /> : <SaveIcon sx={{ fontSize: 16 }} />}
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        disabled={isEdit || formData.generate_voucher_no}
                                        fullWidth
                                        size='small'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Voucher No'}
                                        name='voucher_no'
                                        value={formData.voucher_no || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.voucher_no}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.voucher_type}>Voucher Type</InputLabel>
                                        <Select
                                            size={'small'}
                                            sx={{ backgroundColor: 'white' }}
                                            label="Voucher Type"
                                            name='voucher_type'
                                            value={formData.voucher_type || ""}
                                            onChange={handleFormData}
                                            error={!!validationErrors.voucher_type}
                                        >
                                            {
                                                Constants.JournalVoucherTypes.map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.payment_mode}>Payment Mode</InputLabel>
                                        <Select
                                            size={'small'}
                                            sx={{ backgroundColor: 'white' }}
                                            label="Payment Mode"
                                            name='payment_mode'
                                            value={formData.payment_mode || ""}
                                            onChange={handleFormData}
                                            error={!!validationErrors.payment_mode}
                                        >
                                            {
                                                Constants.PaymentModes.map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.account}>Account</InputLabel>
                                        <Select
                                            size={'small'}
                                            sx={{ backgroundColor: 'white' }}
                                            label="Account"
                                            name='account'
                                            value={formData.account || ""}
                                            onChange={handleFormData}
                                            error={!!validationErrors.account}
                                        >
                                            {accountList.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.accountname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        label="Transaction Date"
                                        type="date"
                                        fullWidth
                                        size='small'
                                        name='transaction_date'
                                        value={formData.transaction_date || ""}
                                        onChange={handleFormData}
                                        sx={{ backgroundColor: 'white' }}
                                        InputLabelProps={{ shrink: true, }}
                                        error={!!validationErrors.transaction_date}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.account}>Account</InputLabel>
                                        <Select
                                            size={'small'}
                                            sx={{ backgroundColor: 'white' }}
                                            label="Account"
                                            name='account'
                                            value={formData.account || ""}
                                            onChange={handleFormData}
                                            error={!!validationErrors.account}
                                        >
                                            {accountList.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.accountname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        label="Remarks"
                                        type="text"
                                        fullWidth
                                        size='small'
                                        name='remarks'
                                        value={formData.remarks || ""}
                                        onChange={handleFormData}
                                        sx={{ backgroundColor: 'white' }}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                                <Grid container item xs={12} md={4} justifyContent={'center'}>
                                    <FormControl fullWidth>
                                        <InputLabel id="is-credit-label">Credit/Debit</InputLabel>
                                        <Select
                                            labelId="is-credit-label"
                                            name="is_credit"
                                            value={formData.is_credit}
                                            onChange={handleFormData}
                                            label="Credit/Debit"
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            {Constants.isCredit.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid container item xs={12} md={12} justifyContent={'flex-end'}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        name="status"
                                        checked={Boolean(formData.status)}
                                        onChange={handleChangeCheckbox}
                                        label="Status"
                                        sx={{ fontWeight: "bold" }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        name="send_mail"
                                        checked={Boolean(formData.send_mail)}
                                        onChange={handleChangeCheckbox}
                                        label="Send Mail"
                                        sx={{ fontWeight: "bold" }}
                                    />
                                </Grid>
                            </Grid>

                            <motion.div
                                style={{
                                    width: '100%',
                                    backgroundColor: '#eeeef0',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    borderRadius: '10px',
                                    border: 'none',
                                    margin: '20px 0px',
                                }}
                                initial={{ opacity: 0, y: 15 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.1, delay: 0.1 }}
                            >
                                {/* <Grid container spacing={1} px={2} pb={2}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="entity_type"
                                        onChange={handleFormData}
                                        value={formData.entity_type}
                                    >
                                        {
                                            Constants.EntityTypes.map((item, index) => (
                                                <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid> */}
                                <AddJournalEntryDetail voucherDetails={handleVoucherDetail} editVoucherDetails={editVoucherDetails} setError={setError} reset={reset} />
                            </motion.div>
                            <Grid container alignItems={'center'} spacing={2}>
                                <Grid item xs={12} md={8} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                >
                                    <Typography variant='body1' color={'green'}>Net Amount: ₹{totalAmount}</Typography>
                                </Grid>
                                <Grid container item xs={12} md={4} alignItems={'center'} justifyContent={'flex-end'}>
                                    <Stack direction={'row'}>
                                        <IconButton
                                            size="small"
                                            onClick={handleSubmit}
                                            sx={{
                                                borderRadius: 2,
                                                px: 2, // Horizontal padding
                                                py: 1, // Vertical padding
                                                backgroundColor: isEdit ? 'success.main' : 'primary.main', // Use theme primary color
                                                color: 'white', // Icon color
                                                '&:hover': {
                                                    backgroundColor: isEdit ? 'success.dark' : 'primary.dark', // Darker shade on hover
                                                },
                                            }}
                                            title={isEdit ? "Update" : "Save"}
                                        >
                                            {isEdit ? <UpdateIcon sx={{ fontSize: 22 }} /> : <SaveIcon sx={{ fontSize: 22 }} />}
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    </motion.div>
                </>
            )}
        </>
    )
}

export default memo(JournalEntryCard);
