import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Calculation from '../../utility/Calculations';

const DownloadOutstandingExcel = async (reportData) => {
    // Define the header row for the Excel file
    const date = Calculation._GetCurrentDate();
    const headers = ['NAME', 'ADDRESS', 'PHONE', 'INV AMOUNT', 'PAYMENT', 'RECEIPT', 'DUE AMOUNT'];

    // Map reportData to an array of rows
    const data = reportData.map(item => [
        item.customer_name??'',
        item.address??'',
        item.phone??'',
        item.total_invoice_amount.toString()??'0',
        item.total_payment_amount.toString()??'0',
        item.total_receipt_amount.toString()??'0',
        item.due_amount.toString()??'0'
    ]);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a new worksheet to the workbook
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'OutstandingReport');

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the Blob as a file
    saveAs(blob, 'OutstandingReport' + date + '.xlsx');
};

// Convert a string to ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

export default DownloadOutstandingExcel;
