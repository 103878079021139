import React, { useState, useEffect, memo } from 'react';
import {
    TextField,
    IconButton,
    Grid,
    Stack,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSelector } from 'react-redux';
import configServ from '../../services/config';
import CheckAndReturn from '../../utility/CheckAndReturn';
import Constants from '../../utility/Constants';
import UploadListImage from '../Upload/UploadListImage';

const initialRow = { entity_type: '', entity_id: '', inv_id: '', invoice_number: '', invoice_amount: '', invoice_date: '', transaction_id: '', amount: '', image: '' };

//FUNCTION
const AddJournalEntryDetail = ({ voucherDetails, editVoucherDetails, setError, reset }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [rows, setRows] = useState([{ ...initialRow }]);
    const [accountList, setAccountList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setRows([{ ...initialRow }]);
    }, [reset]);

    const fetchEditData = async () => {
        await fetchInvoiceByCustomerID(editVoucherDetails[0].customer_id);
        const detail = editVoucherDetails.map((item) => ({
            entity_id: item.customer_id,
            entity_type: item.entity_type,
            fname: customerList.find(x => x.id === item.customer_id).fname,
            inv_id: item.inv_id ?? '',
            invoice_number: item.invoice_number ?? '',
            transaction_id: item.transaction_id ?? '',
            amount: item.amount,
            image: item.image,
        }));
        setRows(detail);
    }

    useEffect(() => {
        if (editVoucherDetails) {
            fetchEditData();
        }
    }, [editVoucherDetails]);

    const fetchAccountList = async () => {
        try {
            const res = await configServ.getAllAccountMasters({
                company_id: company_id,
            });
            if (res.status === 200) {
                setAccountList(res.data);
            }
            else {
                setAccountList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchCustomerList = async () => {
        try {
            const result = await configServ.get_customer({
                company_id: company_id,
            });
            setCustomerList(result);
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchInvoiceByCustomerID = async (customer_id) => {
        try {
            const dataToSend = {
                customer_id: customer_id
            }
            const res = await configServ.getInvoiceByCustomerID(dataToSend);
            if (res.status === 200) {
                setInvoiceList(res.data);
            }
            else {
                setInvoiceList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchSupplierList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id
            }
            const res = await configServ.getSupplier(dataToSend);
            if (res.status === 200) {
                const result = res.data.filter(x => x.status === 1);
                setSuppliers(result);
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAccountList();
        fetchCustomerList();
        fetchSupplierList();
    }, []);

    useEffect(() => {
        validateRows();
    }, [rows]);

    useEffect(() => {
        voucherDetails(rows);
    }, [rows]);

    const handleAddRow = () => {
        setRows([...rows, { ...initialRow }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleAccountChange = async (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;

        if (field === 'entity_id') {
            const account = accountList.find(x => x.id === value);
            newRows[index].fname = account.accountname;
        }

        setRows(newRows);
    };

    const handleChange = async (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;

        if (field === 'entity_id') {
            const customer = customerList.find(x => x.id === value);
            newRows[index].fname = customer.fname;
            await fetchInvoiceByCustomerID(value);
        }
        else if (field === 'invoice_number') {
            const selectedInvoice = invoiceList.find(i => i.invoice_number === value);
            newRows[index].inv_id = selectedInvoice ? selectedInvoice.id : '';
            newRows[index].total_amount = selectedInvoice ? CheckAndReturn.roundToInteger(selectedInvoice.total_amount ?? 0) : '';
            newRows[index].invoice_amount = selectedInvoice ? CheckAndReturn.roundToInteger(selectedInvoice.remaining_amount ?? 0) : '';
            newRows[index].invoice_date = selectedInvoice ? CheckAndReturn.transformToDate3(selectedInvoice.date ?? '') : '';
        }
        else if (field === 'amount') {
            const invoiceAmount = parseFloat(newRows[index].invoice_amount);
            const enteredAmount = parseFloat(value);
            if (enteredAmount > invoiceAmount) {
                newRows[index].amount = invoiceAmount;
                alert('Amount cannot exceed the invoice amount.');
            } else {
                newRows[index].amount = enteredAmount;
            }
        }

        setRows(newRows);
    };

    const handleSupplierChange = async (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;

        if (field === 'entity_id') {
            const supplier = suppliers.find(x => x.id === value);
            newRows[index].fname = supplier.fname;
        }

        setRows(newRows);
    };

    const validateRows = () => {
        const hasError = rows.some(row => !row.amount);
        setError(hasError);
    };

    const handleOpen = () => {
        setOpen(true);
    }
    
    const handleClose = () => {
        setOpen(false)
    }

    //image to base64 converter
    const convertToBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleImageUpload = async (event, index) => {
        const newRows = [...rows];
        const { name, files } = event.target

        const base64Image = await convertToBase64(files[0])
        newRows[index].image = base64Image;
        setRows(newRows);
    };

    const handleRemoveImage = (index) => {
        const newRows = [...rows];
        newRows[index].image = null;
        setRows(newRows);
    };

    //#endregion

    return (
        <Grid container spacing={1} px={2} pb={2}>
            {rows.map((row, index) => (
                <>
                    <UploadListImage open={open} handleClose={handleClose} index={index} handleImageUpload={handleImageUpload} handleRemoveImage={handleRemoveImage} image={row.image} />
                    <Grid container item spacing={1} key={index}>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth size='small' variant="standard">
                                <Autocomplete
                                    id="entity-type-autocomplete"
                                    options={Constants.EntityTypes}
                                    getOptionLabel={(option) => option.label}
                                    value={Constants.EntityTypes.find(item => item.value === row.entity_type) || null}
                                    onChange={(event, newValue) => handleChange(index, 'entity_type', newValue ? newValue.value : '')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Entity Type"
                                            variant="standard"
                                            fullWidth
                                            size='small'
                                            sx={{ background: 'white' }}
                                            error={!row.entity_type}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {
                            row.entity_type === 'account' &&
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        size='small'
                                        id='entity_id'
                                        name='entity_id'
                                        options={accountList}
                                        getOptionLabel={(option) => option.accountname}
                                        sx={{ background: 'white' }}
                                        value={accountList.find((account) => account.id === row.entity_id) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                handleAccountChange(index, 'entity_id', newValue.id);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant='standard' label="Account"
                                                error={!row.entity_id} />
                                        )}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            row.entity_type === 'customer' &&
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        fullWidth
                                        size='small'
                                        id='entity_id'
                                        name='entity_id'
                                        options={customerList}
                                        getOptionLabel={(option) => option.fname + "~" + option.address}
                                        sx={{ background: 'white' }}
                                        value={customerList.find((customer) => customer.id === row.entity_id) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                handleChange(index, 'entity_id', newValue.id);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant='standard' label="Account"
                                                error={!row.entity_id} />
                                        )}
                                    />
                                </Grid>
                                {
                                    invoiceList.length > 0 &&
                                    <>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Autocomplete
                                                size='small'
                                                disablePortal
                                                id='invoice_number'
                                                name='invoice_number'
                                                value={row.invoice_number}
                                                options={invoiceList.map(option => option.invoice_number)}
                                                onChange={(event, newValue) => handleChange(index, 'invoice_number', newValue)}
                                                sx={{ backgroundColor: 'white' }}
                                                renderInput={(params) => <TextField {...params} name='invoice_number' variant='standard' value={row.invoice_number} label={row.invoice_number ? `Date:${row.invoice_date}` : "Invoice"} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <TextField
                                                disabled
                                                fullWidth
                                                size="small"
                                                variant='standard'
                                                sx={{ background: 'white' }}
                                                label={row.invoice_number ? `Total=₹${row.total_amount}` : "Inv Amt"}
                                                InputLabelProps={{ shrink: true }}
                                                value={row.invoice_amount ? `Remains=₹${row.invoice_amount}` : ''}
                                                onChange={(e) => handleChange(index, 'invoice_amount', e.target.value)}
                                            />
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                        {
                            row.entity_type === 'supplier' &&
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        fullWidth
                                        size='small'
                                        id='entity_id'
                                        name='entity_id'
                                        options={suppliers}
                                        getOptionLabel={(option) => option.fname + "~" + option.address}
                                        sx={{ background: 'white' }}
                                        value={suppliers.find((supplier) => supplier.id === row.entity_id) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                handleSupplierChange(index, 'entity_id', newValue.id);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant='standard' label="Account"
                                                error={!row.entity_id} />
                                        )}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                size="small"
                                type='number'
                                variant='standard'
                                sx={{ background: 'white' }}
                                label={'Amount'}
                                value={row.amount}
                                onChange={(e) => handleChange(index, 'amount', e.target.value)}
                                required
                                fullWidth
                                error={!row.amount}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <Stack direction={'row'}>
                                <IconButton onClick={handleAddRow} disabled={index !== rows.length - 1}>
                                    <AddBoxIcon color='primary' />
                                </IconButton>
                                <IconButton onClick={() => handleOpen(index)} disabled={index !== rows.length - 1}>
                                    <CameraAltIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteRow(index)} disabled={index === 0}>
                                    <Delete color='error' />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

export default memo(AddJournalEntryDetail);
