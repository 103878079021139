import React, { useState } from "react";
import ConfigurationCard from '../../Components/Card/ConfigurationCards/ConfigurationCard';
import { useSelector } from 'react-redux';
import ConfigurationTable from "../../Components/Table/configurations/ConfigurationTable";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";

//Function
const Configuration = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const open = useSelector((state) => state.sideExpand.open);

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    const handleEdit = (data) => {
        setEditData(data)
        setIsCreate(true);
        setIsEdit(true);
    };
    //#endregion

    return (
        <>
                <CardHeadMenu
                    prefix={"Na"}
                    suffix={"Na"}
                    title={"CONFIGURATION"}
                    handlePopup={handlePopup}
                    isEdit={isEdit}
                    create={isCreate}
                />

                {
                    isCreate &&
                    <ConfigurationCard isEdit={isEdit} setIsEdit={setIsEdit} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                }

            <ConfigurationTable isChanged={isChanged} handleEdit={handleEdit} />
        </>
    )
}
export default Configuration;