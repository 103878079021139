import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import './Login.css'
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import { assignType } from '../../redux/reducers/Admin'
import Cookies from "universal-cookie/cjs/Cookies";
import { decodeToken } from 'react-jwt'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import jwtDecode from 'jwt-decode';
import LoginIcon from '@mui/icons-material/Login';
import bk from '../../Components/Assets/images/wbk.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Box, Typography, Container, TextField, FormControlLabel, Grid, Checkbox, Button, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import configServ from "../../services/config";
import { rSetCompanyAddress, rSetCompanyCountry, rSetCompanyId, rSetCompanyName, rSetCompanyState, rSetCurrency, rSetEncodedData, rSetIsSuper, rSetLogin, rSetToken, rSetUserId } from "../../redux/reducers/GlobalVariables";
import { useLocation } from 'react-router-dom';
import { persistor } from "../../redux/store";

//FUNCTION
const AdminLogin = () => {
    //#region code
    //const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [loginError, setLoginError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    // const {isUserAdmin} = useSelector((state) => state.user)
    const { company_type } = useSelector((state) => state.admin)
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setLoginError(true);
    };

    const handleCloseDialog = () => {
        setLoginError(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    // Access the state passed from the previous component
    
    const logout = () => {
        //persistor.purge(); // This will remove all data persisted by redux-persist
        localStorage.removeItem('persist:dcrm')
        localStorage.clear();
    };

    useEffect(() => {
        if (localStorage.getItem('session') === 'expired') {
            logout();
            handleOpenDialog('Session Expired', 'Session expired. Please login to continue', 'error');
        }
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // to fetch data from text box
    const handleChange = (e) => {
        const { name, value } = e.target

        // Update the form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }
    }

    const validate = async () => {
        const errors = {};
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.password) {
            errors.password = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    // to handle login
    const handleLogin = async (e) => {
        e.preventDefault();
        const isValid = await validate();
        if (isValid === false) {
            return false;
        }
        try {
            dispatch(triggerLoader());
            const result = await configServ.admin_login(formData);
            if (result.success) {
                dispatch(rSetLogin(true));

                localStorage.setItem("dcrm_token", result.success.token);
                const oneHour = 12 * 60 * 60 * 1000; // 12 hour in milliseconds
                const expirationDate = new Date(Date.now() + oneHour);
                cookie.set('dcrm_token', result.success.token, {
                    //expires: new Date(decodedToken.exp * 1000)
                    expires: expirationDate
                });
                cookie.set('userType', 'admin', {
                    //expires: new Date(decodedToken.exp * 1000)
                    expires: expirationDate
                });

                dispatch(rSetUserId(result.success.userId));
                dispatch(rSetCompanyId(result.success.company_id));
                dispatch(rSetCompanyName(result.success.company_name));
                dispatch(rSetCompanyAddress(result.success.company_address));
                dispatch(rSetCompanyCountry(result.success.country));
                dispatch(rSetCompanyState(result.success.state));
                dispatch(assignType(result.success.company_type));
                dispatch(rSetCurrency(result.success.currency));
                dispatch(rSetIsSuper(result.success.is_super));
                dispatch(rSetEncodedData(result.success.encoded_data));
                dispatch(assignType(result.success.company_type));
                navigate('/Dashboard', { replace: true })
            }
            else {
                handleOpenDialog('Error', 'Invalid Login credentials', 'error');
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', 'Invalid Login credentials', 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    // const checkedLoggedIn = () => {
    //     if (cookie.get('dcrm_token') !== undefined) {
    //         //const decode = jwtDecode(cookie.get('dcrm_token'));
    //         if (user_id === null) {
    //             return;
    //         }
    //         if (cookie.get('userType') === 'customer') {
    //             navigate('/customer-dashboard')
    //         } else {
    //             navigate('/Dashboard')
    //         }
    //     }
    // }

    // useEffect(() => {
    //     checkedLoggedIn()
    // }, [])
    //#endregion

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${bk})`,
                    //background: lightMode ? '#ffffff' : '#090e34',//'linear-gradient(to bottom, #F8F6F4, #D2E9E9)',
                    opacity: 1,
                    zIndex: -1
                }}
            >
                <AlertDialog
                    open={loginError}
                    setOpen={setLoginError}
                    title={title}
                    message={dialogContent}
                    buttonTitle="Ok"
                    buttonType={buttonType}
                />

                <Container component="main" maxWidth="sm">
                    <Box
                        sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            px: 4,
                            py: 8,
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: 'rgb(218, 243, 255)'
                        }}
                    >
                        <Typography variant="h5"><b>Welcome to Dovetail-crm!</b></Typography>
                        <Typography variant="caption">Please sign in to continue as admin</Typography>
                        {/* <Typography component="h1" variant="h5">
                            <AccountCircleTwoToneIcon />Admin Login
                        </Typography> */}
                        <form onSubmit={handleLogin}>
                            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                <Grid item xs={12} md={10} mb={1} mt={3}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={formData.email}
                                        onChange={handleChange}
                                        size={'small'}
                                        sx={{ backgroundColor: 'white' }}
                                        error={!!validationErrors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        size={'small'}
                                        sx={{ backgroundColor: 'white' }}
                                        error={!!validationErrors.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>

                                {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}

                                <Grid item xs={12} md={10} my={1}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        startIcon={<LoginIcon sx={{ margin: '5px 10px' }} />}
                                        sx={{ borderRadius: '20px' }}
                                    >
                                        Login
                                    </Button>
                                </Grid>

                                {/* <Grid container spacing={21}>
                            <Grid item>
                                <Link to='/register'>
                                    <Typography variant="body2" color='primary'>Don't have an account? Register</Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to='/forgetPassword'>
                                    <Typography variant="body2" color='primary'>Forgot Password</Typography>
                                </Link>
                            </Grid>
                        </Grid> */}

                            </Grid>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};
export default AdminLogin;