import React from "react";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from '../../../Components/header/Header/CustHeader'
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
const Notify = () =>{
    return(
        <>
        <SessionVerification/>
        <CustHeader />
        <CustomerSideBar />
        </>
    )
}
export default Notify;