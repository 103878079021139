import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Stack, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import PrintIcon from '@mui/icons-material/Print';
import PaymentIcon from '@mui/icons-material/Payment';
import VisibilityIcon from '@mui/icons-material/Visibility';

// import NestedTable from '../../../pages/Invoice/NestedTable';
import CustNestedTable from './CustNestedTable';
import { motion, AnimatePresence } from 'framer-motion'
import MiniLoader from '../MiniLoader/MiniLoader';
import InvoiceOrientation from '../../../Components/Dialog/InvoiceOrientation';
import { useSelector } from 'react-redux';


export default function CustInvoiceTable({ tableData, miniLoader }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [page, setPage] = React.useState(0);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[1]);
    const [expand, setExpand] = React.useState(false);
    const [expandData, setExpandData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [printData, setPrintData] = React.useState({});

    //   const handleExpand = (id)=>{
    //     setExpand(!expand)
    //     setExpandId(id)
    //   }

    const handleExpand = (data) => {
        setExpand(!expand)
        setExpandData(data)
    }

    const handlePrint = async (inv) => {
        const data = {
            "id": inv.id,
            "invoice_no": inv.invoice_number,
            "user_id": user_id,
            "company_id": company_id,
        }
        setOpen(true);
        setPrintData(data)

    }

    const columns = [
        { id: 'id', label: 'SR. NO.', minWidth: 170 },
        {
            id: 'invoice_no',
            label: 'Invoice Number',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'date',
            label: `Invoice Date`,
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'voucher_type',
            label: 'Voucher Type',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'total_amt',
            label: 'Total Amount',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'payment',
            label: 'Payment',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
    ];


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //#endregion

    return (
        <>
            <InvoiceOrientation open={open} setOpen={setOpen} data={printData} />
            {expand && (<CustNestedTable data={expandData} open={expand} setOpen={setExpand} />)}
            <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ maxHeight: 440 }}
                    component={motion.div}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 440 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={column.id}
                                    //   align={column.align}
                                    //   style={{ top: 0, minWidth: column.minWidth }}
                                    >
                                        <b>{column.label}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <>
                                            <TableRow key={row.id} hover role="checkbox" tabIndex={-1} >
                                                <TableCell sx={{ minWidth: 100 }}>
                                                    <Tooltip title='View Details' arrow>
                                                        <VisibilityIcon onClick={() => { handleExpand(row) }} color='primary' sx={{ mr: '20px', transition: '100ms', cursor: 'pointer', ":hover": { transform: 'scale(1.15)' } }} />
                                                    </Tooltip>
                                                    {++index}
                                                </TableCell>
                                                <TableCell >{row.invoice_number}</TableCell>
                                                <TableCell >{row.date}</TableCell>
                                                <TableCell >{row.voucher_type}</TableCell>
                                                <TableCell >{row.total_amount}</TableCell>
                                                <TableCell >{row.tcs_amount}</TableCell>
                                                <TableCell >{row.total_amount}</TableCell>
                                                <TableCell >
                                                    <Stack direction='row'>
                                                        <Tooltip title='Print' placement="top-start" arrow><Box onClick={() => { handlePrint(row) }} sx={{ borderRadius: 10, width: 'fit-content', transition: '150ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}><PrintIcon /></Box></Tooltip>
                                                        <Tooltip title='Pay' placement="top-start" arrow><Box sx={{ borderRadius: 10, width: 'fit-content', transition: '150ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}><PaymentIcon /></Box></Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                            {/* <AnimatePresence>
                  {expand && (<TableRow>
                    <TableCell colSpan={9}>
                        <Stack justifyContent='center' alignItems='center' >
                            <CustNestedTable data={row} open={expand} setOpen={setExpand}/>
                        </Stack>
                  </TableCell>
                  </TableRow>)}        
                  </AnimatePresence> */}
                                            {/* <NestedTable/> */}
                                            {/* <CustNestedTable data={row} open={expand} setOpen={setExpand}/> */}
                                        </>
                                    );
                                })}
                            {tableData.length === 0 && (<TableRow>
                                <TableCell align='center' colSpan={8}><Typography variant='h6'>No Records to show </Typography></TableCell>
                            </TableRow>)}
                            {miniLoader && (<TableRow>
                                <TableCell align='center' colSpan={8}><MiniLoader /></TableCell>
                            </TableRow>)}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>
        </>
    );
}