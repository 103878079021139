import React from "react";
import Category from "../../Components/masterEntry/Category";
import Batch from "../../Components/masterEntry/Group";
import Units from "../../Components/masterEntry/Units";
import MasterEnteryCard from '../../Components/Card/MaterEntryCards/MasterEntryCard'
import Header from "../../Components/header/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Sidebar from "../../Components/sidebar/SideBar";
import TableCard from "../../Components/Card/MaterEntryCards/TableCard";

import { Outlet } from "react-router-dom";

import { Box, Stack, Paper, Divider, Typography } from "@mui/material";
import { useSelector } from 'react-redux'
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

const Report = () => {

    const open = useSelector((state) => state.sideExpand.open)

    return (
        <>
            <SessionVerificationAdmin/>
            <Header />
            <Sidebar />
            <Box sx={{
                // marginTop:{
                //     xs:7,
                //     sm:8
                // },
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Outlet/>

            </Box>
        </>
    )
}
export default Report