import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import CustHeader from '../../../Components/header/Header/CustHeader'
import {
    Box,
    Stack,
    Paper,
    Divider,
    Typography,
    Button,
    Grid,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Pagination,
    TextField,
    InputAdornment,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
    CircularProgress
} from "@mui/material";
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import http from "../../../services/http";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import ProductCard from "./ProductCard";

//icons
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import configServ from "../../../services/config";

const Product = () => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [item, setItem] = useState([])
    const [filteredItem, setFilteredItem] = useState(item)
    const [category, setCategory] = useState([])
    const [brand, setBrand] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState({
        filter_by_brand: 'all',
        filter_by_category: 'all'
    });
    const [search, setSearch] = useState('');
    const [view, setView] = useState('grid');
    const [filterItems, setFilterItems] = useState(
        {
            "company_id": company_id,
            "brand_id": "",
            "category_id": "",
            "search_item": ""
        }
    );
    const [totalItems, setTotalItems] = useState(0);
    const [searchResult, setSearchResult] = useState(true);
    const [loader, setLoader] = useState(true);

    const itemsPerPage = 15;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const displayedItems = item.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleFilter = (e) => {
        const { name, value } = e.target;
        setFilterItems((state) => ({
            ...state,
            [name]: value
        }))
    };

    const fetchItem = async () => {
        const dataToSend = {
            ...filterItems,
            "perPage": itemsPerPage,
            "page": currentPage
        }
        setLoader(true)
        try {
            const result = await configServ.getItemDetailsByCompIdPage(dataToSend);
            if (result.success) {
                setItem(result.paginationData);
                setTotalItems(result.totalRecords);
                setSearchResult(true);
            } 
            else {
                setSearchResult(false);
            }
        } 
        catch (err) {
            console.log(err);
        }
        finally{
            setLoader(false);
        }
    }

    const fetchCategory = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
              }
            const res = await configServ.getcategory(dataToSend);
            if(res.status === 200){
                setCategory(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchBrand = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
              }
            const res = await configServ.getBrand(dataToSend);
            if(res.status === 200){
                setBrand(res.data);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchBrand();
    }, [])

    useEffect(() => {
        fetchItem();
    }, [filterItems, itemsPerPage, currentPage]);

    const handleSearch = () => {
        setFilterItems((state) => ({
            ...state,
            search_item: search
        }));
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    //#endregion

    return (
        <>
            <SessionVerification />
            <CustHeader />
            <CustomerSideBar />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh',
                backgroundColor: '#EEEEEE77'
            }}>

                <Stack
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                    sx={{
                        padding: 3
                    }}
                >
                    <Divider sx={{ backgroundColor: '#33333377', width: '100%' }} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='space-between' spacing={2} sx={{ width: '100%' }}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={{ xs: '100%', sm: '50%' }} justifyContent='flex-start' alignItems='center'>
                            <Typography variant="body" color='text.secondary'>Filters :</Typography>
                            <FormControl sx={{ minWidth: { xs: '80%', sm: '35%' } }} size="small" >
                                <InputLabel id="demo-simple-select-label">Filter By Category</InputLabel>
                                <Select
                                    label="Filter By Category"
                                    name="category_id"
                                    defaultValue=''
                                    value={filterItems.category_id}
                                    onChange={handleFilter}
                                >
                                    <MenuItem value=''>All</MenuItem>
                                    {category.map((item) => {
                                        return (
                                            <MenuItem key={item.categoryid} value={item.categoryid}>{item.categoryname}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: { xs: '80%', sm: '35%' } }} size="small" >
                                <InputLabel id="demo-simple-select-label">Filter By Brand</InputLabel>
                                <Select
                                    label="Filter By Brand"
                                    name="brand_id"
                                    defaultValue=''
                                    value={filterItems.brand_id}
                                    onChange={handleFilter}
                                >
                                    <MenuItem value=''>All</MenuItem>
                                    {brand.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.brand_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>

                        <TextField
                            label='Search'
                            size="small"
                            sx={{
                                minWidth: { xs: '80%', sm: '30%' }
                            }}
                            name="search_item"
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton autoFocus onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <RadioGroup
                            aria-label="icons"
                            name="icons"
                            row
                            onChange={(e) => { setView(e.target.value) }}
                        >
                            {/* <Stack></Stack> */}
                            <Tooltip title='Grid View' arrow>
                                <FormControlLabel
                                    checked={view === 'grid'}
                                    value="grid"
                                    control={
                                        <Radio
                                            icon={<GridViewIcon />}
                                            checkedIcon={<GridViewIcon />}
                                            color="primary"
                                        />
                                    }
                                />
                            </Tooltip>
                            <Tooltip title='List View' arrow>
                                <FormControlLabel
                                    checked={view === 'list'}
                                    value="list"
                                    control={
                                        <Radio
                                            icon={<FormatListBulletedIcon />}
                                            checkedIcon={<FormatListBulletedIcon />}
                                            color="primary"
                                        />
                                    }
                                />
                            </Tooltip>
                        </RadioGroup>

                    </Stack>
                    <Divider sx={{ backgroundColor: '#33333377', width: '100%' }} />
                    <Grid container spacing={2} minHeight='50vh'>
                        {!loader && searchResult && item.map((item, index) => {
                            return (
                                <Grid key={index} item lg={view === 'list' ? 12 : 4} md={view === 'list' ? 12 : 6} sm={view === 'list' ? 12 : 6} xs={12}>
                                    <ProductCard data={item} view={view} />
                                </Grid>
                            )
                        })}
                        {!searchResult && (
                            <Stack
                                width='100%'
                                justifyContent='center'
                                alignItems='center'
                                my={5}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontStretch: 'expanded'
                                    }}
                                >No items with specified filters</Typography>
                            </Stack>
                        )}
                        {loader && (
                            <Stack
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <CircularProgress />
                            </Stack>
                        )}
                    </Grid>
                    <Pagination
                        // count={Math.ceil(item.length / itemsPerPage)}
                        count={Math.ceil(totalItems / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />

                </Stack>

            </Box>
            <Footer />
        </>
    )
}
export default Product