// UpdateProduct.js
import React from 'react';
import Header from '../../Components/header/Header/Header';
import Sidebar from '../../Components/sidebar/SideBar';
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin';
import QrScannerPage from './QrScannerPage'; // Fix the import path
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const UpdateProduct = () => {
  const open = useSelector((state) => state.sideExpand.open);

  return (
    <>
      <SessionVerificationAdmin />
      <Header />
      <Sidebar />

      <Box
        sx={{
          marginLeft: {
            xs: 7,
            sm: 8,
          },
          ...(open && { marginLeft: 33 }),
          transition: '200ms',
          minHeight: '77vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Include the QR Scanner Page component */}
        <QrScannerPage />
      </Box>
    </>
  );
};

export default UpdateProduct;
