// QrScannerPage.js
import React, { useState } from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import { QrReader } from 'react-qr-reader';

const QrScannerPage = () => {
  const [result, setResult] = useState(null);

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (error) => { 
    console.error('Error while scanning QR code:', error);
  };

  const startScan = () => {
    setTimeout(() => {
      setResult(null);
    }, 500);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '77vh',
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="h4" gutterBottom>
          QR Scanner Page
        </Typography>
        {result ? (
          <Typography variant="body1" gutterBottom>
            Scanned QR Code: {result}
          </Typography>
        ) : (
          <QrReader
            onResult={handleScan}
            onError={handleError}
            style={{ width: '100%', maxWidth: '300px' }}
            facingMode="user"  // or "user"
          />
        )}
        <Button variant="contained" color="primary" onClick={startScan}>
          Scan QR
        </Button>
      </Stack>
    </Box>
  );
};

export default QrScannerPage;
