import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Stack } from '@mui/material';
import { SessionVerificationAdmin } from '../Components/SessionManagement/SessionManagementAdmin';
import Header from '../Components/header/Header/Header';
import Sidebar from '../Components/sidebar/SideBar';

const AdminLayout = ({children}) => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open);
    //#endregion
    return (
        <>
            <SessionVerificationAdmin />
            <Header />
            <Sidebar />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        elevation={0}
                        sx={{
                            width: '100%',
                            mx: 5,
                            my:0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            px: 3,
                            pt: 0,
                            pb: 5
                        }}
                    >
                        {children}
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}

export default AdminLayout;
