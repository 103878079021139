import React from 'react'
// import SrsTable from '../../../Components/Card/ReportCards/SRSCards/SrsTable';
//import MasterEnteryCard from '../../../Components/Card/MaterEntryCards/MasterEntryCard'
// import TableCard from '../../../Components/Card/MaterEntryCards/TableCard';
// import SrsCard from '../../../Components/Card/ReportCards/SRSCards/SrsCard';
//import PurchaseReportTable from '../../../Components/Card/ReportCards/PurchaseReport/PurchaseReportTable';
// import CustomerReportTable from '../../../Components/Card/ReportCards/CustomerReportTable/CustomerReportTable';
import CustomerReportTable from '../../../Components/Card/ReportCards/CustomerReport/CustomerReportTable';
import { Stack, Paper, Divider, Typography } from "@mui/material";
import ReportCard from '../../../Components/Card/ReportCards/ReportCard';
const  CustomerReport = () => {
  return (
    <>

    <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
    >
        <Paper
            sx={{
                width: '90%',
                margin: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 3,
                pb:5
            }}
        >
                            <h4 style={{ textAlign: 'center' }} >Customer Report</h4>
                <br/>
            <ReportCard  title='Report Summary' />
            <Divider />
            <br />
            <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                <Typography fontSize={15} variant="body" fontWeight='bold'></Typography>
                <Divider/>
            </Stack>
            < CustomerReportTable/>
        </Paper>
    </Stack>

</>
    
  )
}

export default  CustomerReport