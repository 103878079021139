import React, { useState } from "react";
import {
    TableContainer,
    Paper,
    TablePagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Stack,
    Box,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CustPOdetail from "./CustPOdetail";
import MiniLoader from '../MiniLoader/MiniLoader';
import configServ from "../../../services/config";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { useDispatch } from "react-redux";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";

const CustPurchaseTable = ({ tableData, miniLoader }) => {

    const [page, setPage] = useState(0);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
  
    const handleOpenDialog = (content) => {
      setDialogContent(content);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
      setDialogContent('');
    };
    const dispatch = useDispatch()

    const columns = [
        {
            id: 'id',
            label: 'SR. NO',
            minWidth: 170
        },
        {
            id: 'order_no',
            label: 'ORDER NO',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'order_date',
            label: 'ORDER DATE',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'order_type',
            label: 'ORDER TYPE',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'delivery_date',
            label: 'DELIVERY DATE',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'validitydate',
            label: 'VALIDITY',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'total_amount',
            label: ' TOTAL AMOUNT',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'action',
            label: 'ACTION',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handlePrint = async (order_id) => {
        const data = {
            "order_id": order_id,
        }
        // console.log(data)
        dispatch(triggerLoader())
        try {
            const result = await configServ.printSalesOrder(data)
            // console.log(result)
            const blobFile = new Blob([result], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blobFile);
            window.open(url)
            dispatch(triggerLoader())
        } catch (err) {
            console.log(err)
            handleOpenDialog('Print failed')
            dispatch(triggerLoader())
        }
    }

    const handleOrderDetail = (id) => {
        setItemId(id);
        setOpen(!open);
    }
    return (
        <>
               <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
            {open && <CustPOdetail open={open} setOpen={setOpen} id={itemId} />}
            <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ maxHeight: 440 }}
                    component={motion.div}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 440 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={column.id}>
                                        <b>{column.label}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <>
                                            <TableRow key={index} hover role="checkbox" tabIndex={-1} >
                                                <TableCell sx={{ minWidth: 100 }}>
                                                    <Tooltip title='View Details' arrow>
                                                        <VisibilityIcon onClick={() => handleOrderDetail(row.id)} color='primary' sx={{ mr: '20px', transition: '100ms', cursor: 'pointer', ":hover": { transform: 'scale(1.15)' } }} />
                                                    </Tooltip>
                                                    {++index}
                                                </TableCell>
                                                <TableCell >{row.order_no || 'N/A'}</TableCell>
                                                <TableCell >{row.order_date || 'N/A'}</TableCell>
                                                <TableCell >{row.order_type || 'N/A'}</TableCell>
                                                <TableCell >{row.delivery_date || 'N/A'}</TableCell>
                                                <TableCell >{row.validitydate || 'N/A'}</TableCell>
                                                <TableCell >{row.total_amount || 'N/A'}</TableCell>
                                                <TableCell >
                                                    <Stack direction='row'>
                                                        <Tooltip title='Print' placement="top-start" arrow><Box onClick={() => { handlePrint(row.id) }} sx={{ borderRadius: 10, width: 'fit-content', transition: '150ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}><PrintIcon /></Box></Tooltip>
                                                        <Tooltip title='Edit' placement="top-start" arrow><Box sx={{ borderRadius: 10, width: 'fit-content', transition: '150ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}><DriveFileRenameOutlineIcon /></Box></Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        </>);
                                })}
                            {tableData.length === 0 && (<TableRow>
                                <TableCell align='center' colSpan={9}><Typography variant='h6'>No Records to show </Typography></TableCell>
                            </TableRow>)}
                            {miniLoader && (<TableRow>
                                <TableCell align='center' colSpan={8}><MiniLoader /></TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
};
export default CustPurchaseTable;