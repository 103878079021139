import React, { memo } from 'react';
import { Card, CardContent, CircularProgress, Grid, Stack, Typography, Box } from '@mui/material';
import CheckAndReturn from '../../utility/CheckAndReturn';

//FUNCTION
const ItemLedgerCard = ({ itemLedger, selectedItem, itemLedgerLoading }) => {

    return (
        <>
            <Grid container alignItems={'center'} spacing={2} mb={1}>
                <Grid container item xs={12} md={12} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Box flex={1} textAlign="center">
                        <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                            {
                                itemLedgerLoading === true ?
                                    <CircularProgress />
                                    :
                                    selectedItem.item_name
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} md={12} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' fontWeight={600}>
                            Opening Quantity:
                        </Typography>
                        <Typography variant='body1' ml={2}>
                            {itemLedgerLoading === true ? <CircularProgress /> : selectedItem.openingquantity}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={12} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' fontWeight={600}>
                            Actual Quantity:
                        </Typography>
                        <Typography variant='body1' ml={2}>
                            {itemLedgerLoading === true ? <CircularProgress /> : selectedItem.actualquantity}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            {
                itemLedger.length > 0 ?
                    itemLedger.map((item, index) => (
                        <Card sx={{ marginBottom: '16px', padding: '16px', backgroundColor: '#f9f9ff' }} key={index}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Type:</Typography>
                                            <Typography variant="body1">{item.type}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Name:</Typography>
                                            <Typography variant="body1">{item.name}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Inv No/Bill No:</Typography>
                                            <Typography variant="body1">{item.entity_number}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Quantity:</Typography>
                                            <Typography variant="body1">{item.quantity}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Rate:</Typography>
                                            <Typography variant="body1">₹{item.rate}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Amount:</Typography>
                                            <Typography variant="body1">₹{CheckAndReturn.roundToInteger(item.amount)}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="body1" color="textSecondary">Date:</Typography>
                                            <Typography variant="body1">{CheckAndReturn.transformToDate3(item.date)}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))
                    :
                    <CircularProgress />
            }
        </>
    );
};

export default memo(ItemLedgerCard);
