import React, { memo, useEffect, useState } from 'react';
import CardHeadMenu from '../Card/CardHeadMenu/CardHeadMenu';
import AlertDialog from '../AlertDialog/AlertDialog';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import configServ from '../../services/config';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

//FUNCTION
const UnregisterPaymentCard = ({ editData, setEditData, setIsChanged, isChanged }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [customers, setCustomers] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [invAmount, setInvAmount] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const handlePopup = () => {
        setIsCreate(!isCreate);
        handleReset();
    }

    const handleChange = async (e) => {
        try {
            const { name, value } = e.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));

            //Reset validations
            if (validationErrors[name]) {
                setValidationErrors((...prev) => ({
                    ...prev,
                    [name]: ''
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCustomerList();
    }, []);

    const handleEdit = async (params) => {
        try {
            setIsEdit(true);
            setIsCreate(true);
            await fetchInvoiceByCustomer(params.ledger_name);
            setFormData(params);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (formData.invoice_number) {
            const invoice = invoices.find(x => x.invoice_number.toLowerCase() === formData.invoice_number.toLowerCase());
            if (invoice !== undefined && invoice !== null) {
                setInvAmount(invoice.total_amount);
            }
        }
    }, [formData.invoice_number]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            handleEdit(editData);
        }
    }, [editData]);

    useEffect(() => {
        if (formData.payment_mode === "Bank") {
            fetchAccountMasterList(1);
        }
        else if (formData.payment_mode === "Cash") {
            fetchAccountMasterList(2);
        }
    }, [formData.payment_mode]);

    const fetchCustomerList = async () => {
        try {
            const res = await configServ.get_customer({
                company_id: company_id,
            });
            setCustomers(res);
        }
        catch (e) {
            console.log(e);
        }
    };

    const fetchInvoiceByCustomer = async (selectedOption) => {
        try {
            const customer = customers.find(x => x.fname.toLowerCase() === selectedOption.toLowerCase());
            const dataToSend = {
                "customer_id": customer.id
            }
            const res = await configServ.getInvoiceByCustomerID(dataToSend);
            if (res.status === 200) {
                setInvoices(res.data);
            }
            else {
                setInvoices([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchAccountMasterList = async (params) => {
        try {
            const dataToSend = {
                "is_super": is_super,
                "user_id": user_id,
                "company_id": company_id,
                "search_by": 'accountclassified',
                "search": params,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getAccountMasters(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setAccounts(result);
            }
            else {
                setAccounts([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCustomerAutocompleteChange = async (selectedOption) => {
        try {
            const customer = customers.find(x => x.fname.toLowerCase() === selectedOption.toLowerCase());
            if (selectedOption) {
                await handleChange({
                    target: { name: "ledger_name", value: selectedOption },
                });
                await handleChange({
                    target: { name: "email", value: customer.email },
                });
                await handleChange({
                    target: { name: "address", value: customer.address },
                });
                await fetchInvoiceByCustomer(selectedOption);
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleInvoiceAutocompleteChange = async (selectedOption) => {
        try {
            if (selectedOption) {
                await handleChange({
                    target: { name: "invoice_number", value: selectedOption },
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePayModeChange = async (e) => {
        try {
            const { name, value } = e.target;
            if (value) {
                await handleChange({
                    target: { name, value },
                });
            }

            if (value !== 'Bank') {
                setFormData((prev) => ({
                    ...prev,
                    bank_ref_no: null,
                    tran_type: null,
                    tran_number: null,
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleAccountChange = async (e) => {
        try {
            const { name, value } = e.target;
            const account = accounts.find(x => x.accountname.toLowerCase() === value.toLowerCase());
            if (value) {
                await handleChange({
                    target: { name, value },
                });
                await handleChange({
                    target: { name: "bank_ref_no", value: account.ifsc_code },
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validateForm = async () => {
        try {
            const errors = {};
            if (!formData.ledger_name) {
                errors.ledger_name = "required";
            }
            if (!formData.amount) {
                errors.amount = "required";
            }
            if (!formData.payment_mode) {
                errors.payment_mode = "required";
            }
            if (formData.payment_mode === 'Bank') {
                if (!formData.tran_type) {
                    errors.tran_type = "required";
                }
                if (!formData.account_name) {
                    errors.account_name = "required";
                }
            }
            setValidationErrors(errors);
            return Object.values(errors).every(error => error === '');
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(triggerLoader());
            const isValidated = await validateForm();
            if (isValidated === false) {
                return false;
            }
            const dataToSend = {
                ...formData,
                company_id: company_id,
            }
            if (!isEdit) {
                const res = await configServ.addUnregisterPayment(dataToSend);
                if (res.status === 200) {
                    handleReset();
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', res.message || "Unregister Payment has been created successfully.", 'success');
                }
                else {
                    handleOpenDialog('Error', "Failed to pay. Please try again.", 'error');
                }
            }
            else {
                const res = await configServ.updateUnregisterPayment(dataToSend);
                if (res.status === 200) {
                    handleReset();
                    setIsChanged(!isChanged);
                    handleOpenDialog('Success', res.message || "Unregister Payment has been updated successfully.", 'success');
                }
                else {
                    handleOpenDialog('Error', "Failed to pay. Please try again.", 'error');
                }
            }
        }
        catch (e) {
            console.log(e);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const hanndleApprovePayment = async () => {
        try {
            const account = accounts.filter(x => x.accountname.toLowerCase() === formData.account_name.toLowerCase());
            const customer = customers.filter(x => x.fname === formData.ledger_name);
            let invoice = null;
            if (formData.invoice_number) {
                invoice = invoices.filter(x => x.invoice_number.toLowerCase() === formData.invoice_number.toLowerCase());
            }
            const dataToSend = {
                "status": true,
                "generate_voucher_no": true,
                "voucher_type": 'receipt',
                "is_credit": true,
                "voucher_no": null,
                "payment_mode": formData.payment_mode === 'Bank' ? 1 : 2,
                "account": account[0].id,
                "transaction_date": formData.trans_date,
                "total_amount": formData.amount,
                "company_id": company_id,
                "user_id": user_id,                
                "entity_type": 'customer',
                "customers": [
                    {
                        "customer_id": customer[0].id,
                        "fname": formData.ledger_name,
                        "inv_id": formData.invoice_number ? invoice[0].id : null,
                        "invoice_number": formData.invoice_number ? formData.invoice_number : null,
                        "invoice_amount": formData.invoice_number ? invAmount : null,
                        "transaction_id": formData.tran_number ? formData.tran_number : null,
                        "transaction_date": formData.trans_date,
                        "amount": formData.amount
                    }
                ]
            }
            const res = await configServ.createVouchers(dataToSend);
            if (res.status === 200) {
                const response = await configServ.deactivateUnregisterPayment({
                    id: formData.id
                });
                if(response.status === 200){

                }
                setIsChanged(!isChanged);
                handleOpenDialog('Success', res.message || 'Voucher created successfully.', 'success');
                handleReset();
            }
            else if (res.status === 400) {
                handleOpenDialog('Warning', res.message || "Failed to create voucher.", 'warning');
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleReset = () => {
        setFormData({});
        setValidationErrors({});
        setEditData({});
        setIsEdit(false);
        setInvoices([]);
        setInvAmount(null)
    }

    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <CardHeadMenu
                prefix={""}
                suffix={""}
                title={"UNREGISTER PAYMENTS"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {
                isCreate === true &&
                <motion.div
                    style={{
                        width: "100%",
                        backgroundColor: "#e3f3fc",
                        padding: 20,
                        paddingLeft: 0,
                        borderRadius: "10px",
                        border: "none",
                        margin: "10px",
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <Grid container alignItems={'center'} justifyContent={'flex-start'} px={3} spacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Unregister Payment Detail</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    id="customer-name"
                                    size="small"
                                    sx={{ backgroundColor: "white" }}
                                    value={formData.ledger_name || ''}
                                    options={customers.sort((a, b) => a.fname.localeCompare(b.fname)).map((option) => option.fname)}
                                    onChange={(e, newValue) => handleCustomerAutocompleteChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={formData.ledger_name || ''}
                                            name="ledger_name"
                                            label="Customer Name"
                                            fullWidth
                                            onChange={handleChange}
                                            error={!!validationErrors.ledger_name}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                invoices.length > 0 &&
                                <>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            id='invoice-number'
                                            size="small"
                                            sx={{ backgroundColor: "white" }}
                                            value={formData.invoice_number || ''}
                                            options={invoices.sort((a, b) => a.invoice_number.localeCompare(b.invoice_number)).map((option) => option.invoice_number)}
                                            onChange={(e, newValue) => handleInvoiceAutocompleteChange(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={formData.invoice_number || ''}
                                                    name="invoice_number"
                                                    label="Invoice No"
                                                    fullWidth
                                                    onChange={handleChange}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            id='invoice-amount'
                                            type='number'
                                            size='small'
                                            sx={{ backgroundColor: 'white' }}
                                            label='Inv Amount'
                                            name='invAmount'
                                            inputProps={{ readOnly: true }}
                                            value={invAmount || ''}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    id='amount'
                                    type='number'
                                    size='small'
                                    sx={{ backgroundColor: 'white' }}
                                    label='Amount'
                                    name='amount'
                                    value={formData.amount || ''}
                                    onChange={handleChange}
                                    error={validationErrors.amount}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel error={validationErrors.payment_mode}>Payment Mode</InputLabel>
                                    <Select
                                        id='payment-mode'
                                        size='small'
                                        sx={{ backgroundColor: 'white' }}
                                        label='Payment Mode'
                                        name='payment_mode'
                                        value={formData.payment_mode || ''}
                                        onChange={handlePayModeChange}
                                        error={validationErrors.payment_mode}
                                    >
                                        <MenuItem value={'Cash'}>Cash</MenuItem>
                                        <MenuItem value={'Bank'}>Bank</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                formData.payment_mode === 'Bank' &&
                                <>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel error={validationErrors.tran_type}>Transaction Type</InputLabel>
                                            <Select
                                                id='transaction-type'
                                                size='small'
                                                sx={{ backgroundColor: 'white' }}
                                                label='Transaction Type'
                                                name='tran_type'
                                                value={formData.tran_type || ''}
                                                onChange={handleChange}
                                                error={validationErrors.tran_type}
                                            >
                                                <MenuItem value={'Online'}>Online</MenuItem>
                                                <MenuItem value={'Cheque'}>Cheque</MenuItem>
                                                <MenuItem value={'Electronic'}>Electronic</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            id='tran_number'
                                            type='text'
                                            size='small'
                                            sx={{ backgroundColor: 'white' }}
                                            label='Transaction No'
                                            name='tran_number'
                                            value={formData.tran_number || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel error={!!validationErrors.account_name}>Account</InputLabel>
                                    <Select
                                        size={'small'}
                                        sx={{ backgroundColor: 'white' }}
                                        label="Account"
                                        name='account_name'
                                        value={formData.account_name || ""}
                                        onChange={handleAccountChange}
                                        error={!!validationErrors.account_name}
                                    >
                                        {accounts.map((item) => (
                                            <MenuItem key={item.id} value={item.accountname}>
                                                {item.accountname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id='trans_remarks'
                                    type='text'
                                    size='small'
                                    multiline
                                    minRows={2}
                                    sx={{ backgroundColor: 'white' }}
                                    label='Remarks'
                                    name='trans_remarks'
                                    value={formData.trans_remarks || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid container item xs={12} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
                                {
                                    isEdit &&
                                    <>
                                        <Grid item xs={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                onClick={hanndleApprovePayment}
                                                startIcon={<DoneOutlineIcon />}
                                            >
                                                Approve Payment
                                            </Button>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={6} md={2}>
                                    <Button
                                        type='reset'
                                        fullWidth
                                        variant='contained'
                                        color='secondary'
                                        startIcon={<RotateLeftIcon />}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color={isEdit ? 'success' : 'primary'}
                                        startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                    >
                                        {isEdit ? 'Update' : 'Save'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </motion.div>
            }
        </>
    )
}

export default memo(UnregisterPaymentCard);
