import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import React, { memo } from 'react';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import useDeviceDetect from '../../Detect/useDeviceDetect ';
import AccountMasterLedgerTable from './AccountMasterLedgerTable';
import AccountMasterLedgerCard from './AccountMasterLedgerCard';

//FUNCTION
const AccountMasterPopup = ({ openAccountMaster, closeAccountMaster, accountMaster, selectedAccount }) => {
    const { isMobile } = useDeviceDetect();

    return (
        <>
            <Dialog
                open={openAccountMaster}
                onClose={closeAccountMaster}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '80%',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                                {selectedAccount ? selectedAccount.accountname : <CircularProgress />}
                            </Typography>
                        </Box>
                        <Box position="absolute" right={16}>
                            <IconButton onClick={closeAccountMaster}>
                                <DisabledByDefaultIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        isMobile === true ?
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }}>
                                <AccountMasterLedgerCard accountMaster={accountMaster} />
                                <h6>Table</h6>
                            </Box>
                            :
                            <AccountMasterLedgerTable accountMaster={accountMaster} />
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default memo(AccountMasterPopup);
