import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    Grid,
    TextField,
} from "@mui/material";
import { motion } from "framer-motion";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useDispatch, useSelector } from "react-redux";
import Calculation from '../../../utility/Calculations';
import AlertDialog from '../../AlertDialog/AlertDialog';
import configServ from '../../../services/config';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';

//FUNCTION
const DispatchPopup = ({ openDispatch, closeDispatchPopup, dispatchDetail }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);

    const formDataInitial = {
        Date: Calculation.GetCurrentDate()
    }
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [accountList, setAccountList] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    useEffect(() => {
        if (dispatchDetail) {
            const quantity = dispatchDetail.items.reduce((sum, item) => {
                return sum + parseInt(item.qty);
            }, 0);

            setFormData((prev) => ({
                ...prev,
                invoice_no: dispatchDetail.invoice_number,
                amount: dispatchDetail.total_amount,
                qnty: quantity,
            }));
        }
    }, [dispatchDetail]);


    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
        closeDispatchPopup();
    };

    const handleFormData = (event) => {
        try {
            const { name, value } = event.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.invoice_no) {
                errors.invoice_no = 'required.';
            }
            if (formData.transportationCost) {
                if(!formData.voucher_type){
                    errors.voucher_type = 'required.';
                }
                if(!formData.account){
                    errors.account = 'required.';
                }
            }
            if (!formData.qnty) {
                errors.qnty = 'required.';
            }
            if (!formData.amount) {
                errors.amount = 'required.';
            }
            if (!formData.Date) {
                errors.Date = 'required';
            }
            setValidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleVoucher = async () => {
        try {
            const voucher = {
                "generate_voucher_no": true,
                "voucher_type": formData.voucher_type,
                "payment_mode": null,
                "transaction_date": Calculation.GetCurrentDate(),
                "remarks": null,
                "entity_type": "account",
                "status": true,
                "account": formData.account,
                "total_amount": dispatchDetail.total_amount,
                "company_id": company_id,
                "user_id": user_id,
                "customers": [
                    {
                        "customer_id": "",
                        "fname": "",
                        "inv_id": "",
                        "invoice_number": "",
                        "invoice_amount": "",
                        "transaction_id": "",
                        "transaction_date": Calculation.GetCurrentDate(),
                        "amount": dispatchDetail.total_amount
                    }
                ]
            }

            return voucher;
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            dispatch(triggerLoader());
            e.preventDefault();
            const isValid = await validate();
            if (isValid === false) {
                return false;
            }
            let voucher = null;
            if (formData.transportationCost) {
                voucher = await handleVoucher();
            }
            const dataToSend = {
                ...formData,
                company_id: company_id,
                voucher: voucher,
            }
            const res = await configServ.createDispatch(dataToSend);
            if (res.status === 200) {
                handleOpenDialog('Success', res.message || "Dispatched successfully.", 'success');
                handleReset()
            }
            else {
                handleOpenDialog('Error', res.message || "Dispatch failed. Please try again.", 'error');
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData(formDataInitial);
            setValidationErrors({});
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchAccountMasterList = async (params) => {
        try {
            const dataToSend = {
                "is_super": is_super,
                "user_id": user_id,
                "company_id": company_id,
                "search_by": 'accountclassified',
                "search": params,
                "per_page": null,
                "page": null
            }
            const res = await configServ.geVoucherEntrytAccount(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setAccountList(result);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAccountMasterList();
    }, []);
    //#endregion

    return (
        <>
            <Dialog
                open={openDispatch}
                onClose={closeDispatchPopup}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontSize={{ xs: '16px', md: '22px' }} fontWeight={700}>
                                DISPATCH
                            </Typography>
                        </Box>
                        <Box position="absolute" right={10}>
                            <IconButton onClick={closeDispatchPopup}>
                                <DisabledByDefaultIcon color="error" sx={{ fontSize: 30 }} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <AlertDialog
                        open={dialogOpen}
                        setOpen={handleCloseDialog}
                        title={title}
                        message={dialogContent}
                        buttonTitle="Ok"
                        buttonType={buttonType}
                    />
                    <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: 0.1 }}
                    >
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                sx={{ p: 0, }}
                                spacing={1}
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                    {/* <Autocomplete
                                        disablePortal
                                        fullWidth
                                        size='small'
                                        id="combo-box-demo"
                                        name='invoice_no'
                                        options={invoices.map((option) => option.invoice_number)}
                                        sx={{ background: 'white' }}
                                        value={formData.invoice_no}
                                        onChange={(event, newValue) => handleFormData({ target: { name: 'invoice_no', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField {...params} value={formData.invoice_no} label="Invoice No" />
                                        )}
                                    /> */}
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="combo-box-demo"
                                        name='invoice_no'
                                        sx={{ background: 'white' }}
                                        value={formData.invoice_no}
                                        //onChange={handleFormData}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transporter Name'}
                                        name='transporter_name'
                                        value={formData.transporter_name || ""}
                                        onChange={handleFormData}
                                    //error={!!validationErrors.transporter_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Phone'}
                                        name='TransporterMobileNo'
                                        value={formData.TransporterMobileNo || ""}
                                        onChange={handleFormData}
                                    //error={!!validationErrors.TransporterMobileNo}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Vehicle No'}
                                        name='vehicle_num'
                                        value={formData.vehicle_num || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Quantity'}
                                        name='qnty'
                                        value={formData.qnty || ""}
                                        //onChange={handleFormData}
                                        InputProps={{ readOnly: true }}
                                        error={!!validationErrors.qnty}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Amount'}
                                        name='amount'
                                        value={formData.amount || ""}
                                        //onChange={handleFormData}
                                        InputProps={{ readOnly: true }}
                                        error={!!validationErrors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='date'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Dispatch Date'}
                                        name='Date'
                                        InputLabelProps={{ shrink: true }}
                                        value={formData.Date || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.Date}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transport Cost'}
                                        name='transportationCost'
                                        value={formData.transportationCost || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                {
                                    formData.transportationCost &&
                                    <>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel error={!!validationErrors.voucher_type}>Voucher Type</InputLabel>
                                                <Select
                                                    size={'small'}
                                                    sx={{ backgroundColor: 'white' }}
                                                    label="Voucher Type"
                                                    name='voucher_type'
                                                    value={formData.voucher_type || ""}
                                                    onChange={handleFormData}
                                                    error={!!validationErrors.voucher_type}
                                                >
                                                    <MenuItem value={'payment'}>
                                                        Payment
                                                    </MenuItem>
                                                    <MenuItem value={'receipt'}>
                                                        Receipt
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel error={!!validationErrors.account}>Account</InputLabel>
                                                <Select
                                                    size={'small'}
                                                    sx={{ backgroundColor: 'white' }}
                                                    label="Account"
                                                    name='account'
                                                    value={formData.account || ""}
                                                    onChange={handleFormData}
                                                    error={!!validationErrors.account}
                                                >
                                                    {accountList.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.accountname}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                                <Grid container item xs={12}>
                                    {/* <Grid container item xs={12} md={6} justifyContent={'flex-start'}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            name="status"
                                            checked={Boolean(formData.status)}
                                            onChange={handleChangeCheckbox}
                                            label="Status"
                                            sx={{ fontWeight: "bold" }}
                                        />
                                    </Grid> */}
                                    <Grid container item xs={12} md={12} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                                        <Button
                                            type='reset'
                                            color={'secondary'}
                                            startIcon={<RotateLeftIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type='submit'
                                            color={'primary'}
                                            startIcon={<SaveIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            {'Save'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default memo(DispatchPopup);
