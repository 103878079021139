import React, { useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import { Card, CardContent, CardMedia, Typography, Stack, Box, Button } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";

const RecentlyAddedItem = () => {
    //#region code
    const { company_id, currency } = useSelector((state) => state.GlobalVariables);
    const navigate = useNavigate();
    const { company_type } = useSelector((state) => state.admin);

    const [onHover, setOnHover] = useState(true)
    const [catalog, setCatalog] = useState([])
    const [image, setImage] = useState(
        `https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image`
    )

    const fetchCatalog = async () => {
        try {
            const res = await configServ.getCatalogCustomer({ "company_id": company_id });
            if(res.success === true){
                setCatalog(res.data.catalog_items)
            }
            else{
                setCatalog([]);
            }
        } 
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchCatalog()
    }, []);

    const showHover = () => {
        setOnHover(false);
    }
    const hideHover = () => {
        setOnHover(true);
    }
    //#endregion

    return (
        <>
            <Box sx={{
                backgroundColor: '#eeeeeeaa',
                padding: '20px',
                width: { xs: '100%', md: '50%' },
            }}>
                <Stack justifyContent='center' alignItems='center'
                    sx={{ background: 'black', mb: '10px' }}>
                    <Typography variant="h6" color='white' >
                        {/* <b><i> Recently Added {companyType === 'service'?'Services':'Products'}</i></b> */}
                        <b><i> Catalog</i></b>
                    </Typography>
                </Stack>
                <Carousel
                    autoPlay infiniteLoop transitionTime={500} showThumbs={false}
                    sx={{
                        paddingTop: '20px',
                        width: '70vw',
                        height: '170px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                    NextIcon={<NavigateNextOutlinedIcon />}
                    PrevIcon={<ArrowBackIosNewOutlinedIcon />}>
                    {/* {
                            catalog.length == 0 && 

                        } */}
                    {catalog.map((item) => (
                        <div key={item.id}>
                            <Card>
                                <Box onMouseEnter={showHover}
                                    onMouseLeave={hideHover}
                                    sx={{ position: 'relative' }}>
                                    <CardMedia component="img" alt={item.item.image[0]?.alt} height="300" src={item.item.image.length !== 0 ? `data:image/png;base64,${item.item.image[0]?.image}` : ""} />
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            background: '#33333388',
                                            position: 'absolute',
                                            bottom: 0,
                                            width: '100%',
                                            height: '60%',
                                            color: 'white',
                                            opacity: `${onHover ? 0 : 1}`,
                                            padding: 3,
                                            transition: 'opacity 0.15s ease-in-out',
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="div"
                                        >
                                            {item.item.item_name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {item.item.description}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>{currency} {item.item.rate_unit}{' '}{company_type === 'service' ? item.item.SubscriptionStatus : ''}</span>
                                        </Typography>
                                    </Stack>
                                </Box>
                                <CardContent>
                                    {/* <Typography variant="h5" component="div">
                                        {item.title}
                                    </Typography> */}
                                    <Stack direction='row' spacing={2} sx={{ fontSize: { xs: '0.75rem', sm: '0.8', md: '0.9rem' } }}>
                                        <Button
                                            onClick={() => { navigate('/product-details', { state: { data: item.item, image: item.item.image } }) }}
                                            variant='outlined'
                                            size="small"
                                            sx={{
                                                color: '#333',
                                                borderColor: '#333',
                                                backgroundColor: '#33333311',
                                                // width: '30%',
                                                flex: 1,
                                                fontSize: {
                                                    xs: '0.35rem',
                                                    sm: '0.45rem',
                                                    md: '0.65rem'
                                                }
                                            }}
                                        >
                                            Learn More
                                        </Button>
                                        {/* <Button
                                            variant='contained'
                                            size="small"
                                            sx={{
                                                width: '30%',
                                                ":hover": {
                                                    backgroundColor: '#333333dd'
                                                },
                                                fontSize: {
                                                    xs: '0.35rem',
                                                    sm: '0.45rem',
                                                    md: '0.65rem'
                                                }
                                            }}
                                        >
                                            Add to Cart
                                        </Button> */}
                                        <Button
                                            onClick={() => { navigate('/product') }}
                                            variant='outlined'
                                            size="small"
                                            sx={{
                                                color: '#333',
                                                borderColor: '#333',
                                                backgroundColor: '#33333311',
                                                // width: '30%',
                                                flex: 1,
                                                fontSize: {
                                                    xs: '0.35rem',
                                                    sm: '0.45rem',
                                                    md: '0.65rem'
                                                }
                                            }}
                                        >
                                            View All Products
                                        </Button>
                                    </Stack>
                                    <br />
                                </CardContent>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </Box>
        </>
    )

}
export default RecentlyAddedItem