import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import React, { memo } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import uploadImg from '../Assets/images/upload.jpeg';
import PopupDialogTitle from '../Dialog/PopupDialogTitle';

const UploadListImage = ({ open, handleClose, index, handleImageUpload, handleRemoveImage, image }) => {
  //#region code
  //#endregion

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-list-image-title"
        aria-describedby="upload-list-image-description"
        fullWidth
        maxWidth={'sm'}
        PaperProps={{
          sx: {
            width: 'auto',
            height: 'auto',
          },
        }}
      >
        <PopupDialogTitle handleClose={handleClose} show={false} />
        <br />
        <DialogContent>
          <Box
            borderRadius={5} 
            p={4} 
            sx={{backgroundColor:'#f7f4e2'}}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              key={index}
            >
              <Grid container item xs={12} md={12} alignItems={'center'} justifyContent={'center'}>
                {image ? (
                  <>
                    <img src={image} alt={`Row ${index}`} style={{ width: 100, height: 100, marginRight: 16 }} />
                  </>
                )
                  :
                  <img src={uploadImg} alt='upload image' style={{ width: 100, height: 100, marginRight: 16 }} />
                }
              </Grid>
              <Grid container item xs={12} md={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <InputLabel htmlFor={`upload-button-${index}`} sx={{ cursor: 'pointer' }}>
                  <input
                    id={`upload-button-${index}`}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => handleImageUpload(e, index)}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mr: 2 }}
                  >
                    Upload
                  </Button>
                </InputLabel>

                <Button
                  variant="contained"
                  color='error'
                  component="span"
                  startIcon={
                    <DeleteIcon />
                  }
                  onClick={() => handleRemoveImage(index)}
                  sx={{ mr: 2 }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(UploadListImage);
