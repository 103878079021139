import React, { useState, useEffect } from 'react'
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import configServ from '../services/config';


const FetchItemSizeById = ({ itemData, itemList, handleChange, index, errors }) => {

    const [itemSize, setItemSize] = useState([])

    const fetchItemSize = async () => {
        try {
            const result = await configServ.getItemSize({ "item_id": itemData.item_id })
            const filtered = result.filter((item) => {
                return item.size !== 'Primary'
            })
            // console.log(filtered)
            setItemSize(filtered)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // console.log(itemData)
        if (itemData.item_id !== undefined) {
            fetchItemSize()
        }
    }, [itemData.item_id])


    return (
        <>
            <FormControl
                fullWidth
                size='small'
                sx={{
                    backgroundColor: 'white'
                }}
            >
                <InputLabel >Size</InputLabel>
                <Select
                    label="Size"
                    name='size_id'
                    sx={{
                        height: '100%'
                    }}
                    onChange={(e) => {
                        handleChange(e, itemData.id)
                        // console.log(e)
                    }
                    }
                    value={itemData.size_id || ''}
                    error={errors[`${index}`] !== undefined ? !!errors[`${index}`].size_id : false}
                >
                    {itemSize.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>{item.size}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </>
    )
}

export default FetchItemSizeById