import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import {motion, AnimatePresence} from 'framer-motion'
import http from '../../../services/http';
import { Dialog, IconButton, Stack, Typography } from '@mui/material';
import MiniLoader from '../MiniLoader/MiniLoader';
import CloseIcon from '@mui/icons-material/Close';
import configServ from '../../../services/config';

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }


export default function CustNestedTable({data, open, setOpen}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [NestedTableData, setNestedTableData] = useState([])
  const [miniLoader, setMiniLoader] = useState(false)

  const fetchNestedTableData = async()=>{
    setMiniLoader(true)
    try{
        const result = await configServ.invoice_detail({"invoice_id":data.id});
        // console.log(result)
        setNestedTableData(result)
        setMiniLoader(false)
    }catch(err){
        console.log(err)
        setMiniLoader(false)
    }
}

    useEffect(()=>{
        fetchNestedTableData()
    },[open])
    const handleClose = () => {
      setOpen(false);
    };
  const columns = [
    { id: 'slno', label: 'SR. NO.', minWidth: 170 },
      {
        id: 'item_name',
        label: 'ITEM NAME',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
    {
      id: 'qty',
      label: `QTY`,
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'rate',
      label: 'RATE',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'discount',
      label: 'DISCOUNT',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
        id: 'amount',
        label: 'AMOUNT',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
      },
  ];



  return (
    <>
    {/* <Paper sx={{Width:'90%'}}> */}
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{mt:2,ml:2, color:'text.secondary'}} variant='body2'>Invoice details for {data.invoice_number}</Typography>
            <IconButton onClick={handleClose}>
                <CloseIcon color='primary'/>
            </IconButton>
        </Stack>
      <TableContainer sx={{ Width:'100%' }}
                  component={motion.div}
                  initial={{ height:0, opacity:0 }}
                  animate={{ height:'100%', opacity:1}}
                  transition={{ duration: 0.15}}
                  exit={{ height:0, opacity:0, transition:{duration:0.1}  }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                //   align={column.align}
                //   style={{ top: 0, minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {NestedTableData
              .map((row,index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell sx={{minWidth:100}}>{++index}</TableCell>
                        <TableCell >{row.itemname}</TableCell>
                        <TableCell >{row.qty}</TableCell>
                        <TableCell >{row.rate}</TableCell>
                        <TableCell >{row.discount}</TableCell>
                        <TableCell >{row.amount}</TableCell>             
                  </TableRow>
                );
              })}
                    {miniLoader && (<TableRow>
                        <TableCell align='center' colSpan={8}><MiniLoader/></TableCell>
                    </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      </Dialog>
     {/* </Paper> */}
    </>
  );
}