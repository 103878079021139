
import { useJwt } from 'react-jwt'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import Cookies from 'universal-cookie';
import Cookies from "universal-cookie/cjs/Cookies";
import { rSetLogin } from '../../redux/reducers/GlobalVariables';
//import { useHistory } from 'react-router-dom';

export const SessionVerificationAdmin = () => {
    //const history = useHistory();

    //const { user_id } = useSelector((state) => state.GlobalVariables);
    //const token = localStorage.getItem("token");
    //const { isExpired } = useJwt(token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookie = new Cookies();
    // Get the value of the cookie named 'dcrm_token'
    const cook = cookie.get('dcrm_token');

    // Check if the cookie exists
    if (cook === undefined) {
        localStorage.setItem('session','expired');
        dispatch(rSetLogin(false));
        navigate('/admin-login');
        //history.push('/admin-login', { message: 'Unauthorized access' });
    } 

    // if (isExpired) {
    //     navigate('/admin-login')
    // }
    // if (cookie.get('dcrm_token') === undefined) {
    //     navigate('/admin-login')
    // }
    // if (token === undefined) {
    //     navigate('/admin-login')
    // }
    // if (user_id === undefined) {
    //     navigate('/admin-login')
    // }
}
