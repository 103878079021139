import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniLoader from '../MiniLoader/MiniLoader';
import configServ from "../../../services/config";
import { useSelector } from "react-redux";

const LatestTransaction = () => {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const [miniLoader, setMiniLoader] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchLatestTransaction();
    }, []);

    const fetchLatestTransaction = async () => {
        setMiniLoader(true)
        try {
            const result = await configServ.getrecenttrans({ "cust_id": user_id })
            // console.log(result.data);
            setData(result.data);
        } catch (error) {

        }
    }

    const columns = [
        {
            id: 'id',
            label: 'SR. NO',
            minWidth: 170
        },
        {
            id: 'date',
            label: ' DATE',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'voucherNo',
            label: 'VOUCHER NO.',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'amount',
            label: 'AMOUNT',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        }
    ]
    //#endregion

    return (
        <>
            <Box sx={{
                backgroundColor: '#eeeeeeaa',
                padding: '20px',
                width: { xs: '100%', md: '50%' },
            }}>
                <Stack justifyContent='center' alignItems='center'
                    sx={{ background: 'black', mb: '10px' }}>
                    <Typography variant="h6" color='white' >
                        <b><i> Latest Transaction Details</i></b>
                    </Typography>
                </Stack>
                <Stack direction='row' spacing={10} justifyContent='center' alignItems='center'
                    sx={{ background: 'white', mb: '10px', height: 50, }}>
                    {columns.map((item) =>
                        <Typography key={item.id} ><b>{item.label}</b></Typography>
                    )}
                </Stack>
                {data.map((item, index) =>
                    <Stack direction="row" spacing={10} justifyContent='center' alignItems='center'
                        sx={{ background: 'white', height: 58, mb: '10px' }}>
                        <Typography key={index}>{++index}</Typography>
                        <Typography >{item.date}</Typography>
                        <Typography>{item.voucherNo}</Typography>
                        <Typography>{item.amount}</Typography>
                    </Stack>
                )}

                {/* <Stack sx={{ background: 'white', height: 70, mb: '10px' }}>
                </Stack>
                <Stack sx={{ background: 'white', height: 70, mb: '10px' }}>
                </Stack>
                <Stack sx={{ background: 'white', height: 70, mb: '10px' }}>
                </Stack>
                <Stack sx={{ background: 'white', height: 70, mb: '10px' }}>
                </Stack> */}
            </Box>
        </>
    )
};
export default LatestTransaction;