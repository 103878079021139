
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Header.css';
import { IconButton, Stack, Box, Menu, MenuItem, Button, Typography, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import configServ from '../../../services/config';

import SiteLogoInitial from '../../Assets/images/SiteIcon.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CompanyLogo from '../../Assets/images/flag.png'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import Logout from '../../Logout/Logout';
import GoogleTranslate from '../../GoogleTranslator';

function CustHeader() {
    //#region code
    const { company_id, companyName, user_name } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const cartValue = useSelector((state) => state.cart.cartValue)
    const [companyLogo, setCompanyLogo] = useState('')
    const [isLogutDialogOpen, setIsLogutDialogOpen] = React.useState(false);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const cartValueCheck = ()=>{
    //     console.log(cartValue.length)
    // }

    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open2 = Boolean(anchorEl2);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
        //   console.log(anchorEl2)
    };

    const assignCompanyLogo = async () => {
        try {
            const result = await configServ.company_logo({ "company_id": company_id });
            // console.log(result.image)
            setCompanyLogo(result.image)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        assignCompanyLogo()
    }, [])

    const handleLogout = () => {
        setIsLogutDialogOpen(!isLogutDialogOpen)
    }

    const handleCart = () => {
        //   setCartCount(cartCount + 1);
        navigate('/cart')
    }
    const handleNotification = () => {
        setNotificationCount(notificationCount + 1);
        navigate('/customer-Notifications')
    }
    //#endregion

    return (
        <>
            <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
            <Box
                sx={{
                    width: '100%',
                    height: 60,
                    ...(open && { marginLeft: 25, width: '88.55%' }),
                    transition: '200ms',
                    zIndex: 1100,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: 5,
                    boxShadow: 1
                }}
            >
                <Stack width={60} marginLeft={10} height='100%' justifyContent='center'>
                    {/* <IconButton 
            width={60}
            id="basic-button"
            aria-controls={open1 ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? 'true' : undefined}
            // onClick={handleClick}
          > */}
                    {companyLogo === '' ? <span style={{ fontSize: '12px' }}>{companyName}</span> : <img src={`data:image/png;base64,${companyLogo}` || SiteLogoInitial} alt="logoInitial" height='60' title={companyName} />}
                    {/* <img src={companyLogo} alt="logoInitial" width='50' /> */}
                    {/* </IconButton> */}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent='flex-end'
                    spacing={1}
                    sx={{
                        ...(open && {
                            mr: {
                                xs: '30%',
                                sm: '20%',
                                md: '8%',
                                lg: '2%'
                            }
                        })
                    }}
                >
                    {/* <GoogleTranslate/> */}
                    {/* <IconButton
                        id="basic-button2"
                        // aria-controls={open2 ? 'basic-menu' : undefined}
                        // aria-haspopup="true"
                        // aria-expanded={open2 ? 'true' : undefined}
                        //  onClick={handleNotification}
                        sx={{
                            // width: '5%'
                        }}
                    >
                        <div>
                            <div className={notificationCount > 0 ? "red-dot" : "no-dot"}>{notificationCount}</div>
                            <NotificationsIcon fontSize='large' />
                        </div>
                    </IconButton> */}
                    <IconButton
                        id="basic-button2"
                        // aria-controls={open2 ? 'basic-menu' : undefined}
                        // aria-haspopup="true"
                        // aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleCart}
                        sx={{
                            // width: '5%',
                            // border:'1px solid red'
                        }}
                    >
                        <div className={cartValue.length > 0 ? "red-dot" : "no-dot"}>{cartValue.length}</div>
                        <ShoppingCartIcon fontSize='large' />
                    </IconButton>
                    <IconButton
                        id="basic-button2"
                        aria-controls={open2 ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleClick2}
                        sx={{
                            // width: '5%',
                            // border:'1px solid red'
                        }}
                    >
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <AccountCircleIcon fontSize='large' />
                            {/* <img src={'blob:http://localhost:3000/27840c1e-bc08-46a5-bf52-469f095caf76'} alt="Profile" width='100%' height='100%' /> */}
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    display: {
                                        xs: 'none',
                                        sm: 'block'
                                    }
                                }}
                            >
                                {user_name}
                            </Typography>
                        </Stack>
                    </IconButton>
                    {/* <Button onClick={cartValueCheck}>cart check</Button> */}
                </Stack>
                <Menu
                    id="basic-menu2"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button2',
                    }}
                >
                    <Link style={{ textDecoration: 'none' }} to='/customer-profile'><MenuItem onClick={handleClose2} sx={{ padding: 0 }}><Button sx={{ color: 'black', padding: 2 }} startIcon={<AccountBoxIcon />}>My Profile</Button></MenuItem></Link>
                    <MenuItem onClick={() => { handleClose2(); handleLogout() }} sx={{ padding: 0 }}><Button sx={{ color: 'black', padding: 2 }} startIcon={<LogoutIcon />}>Log Out</Button></MenuItem>
                </Menu>

            </Box>
            <Divider />
        </>
    )
}

export default CustHeader