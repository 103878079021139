import React, { useState } from "react";
import InvoiceCard from "../../Components/Card/InvoiceCards/InvoiceCard";
import AllInvoices from "./AllInvoice";
import { useSelector } from 'react-redux'
import { Container } from "react-bootstrap";
import InvoiceTable from "../../Components/Card/InvoiceCards/InvoiceTable";
import EPopup from "./EOptions/EPopup";
import { useNavigate } from "react-router-dom";

//FUNCTION
const Invoice = () => {
    //#region code
    const navigate = useNavigate();
    const { isLogin, company_id } = useSelector((state) => state.GlobalVariables);
    if (isLogin === false) {
        navigate('/admin-login');
    }
    const open = useSelector((state) => state.sideExpand.open);
    const [listAll, setListAll] = useState(false)
    const [addInvoice, setAddInvoice] = useState(false);
    const [editData, setEditData] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [edata, setEdata] = useState({});

    const handleDialogOpen = () => {
        setListAll(true);
    };

    const handleDialogClose = () => {
        setListAll(false);
    };

    const handleEditData = (data) => {
        setEditData(data[0]);
    }

    const handleClickOpen = (data) => {
        setOpenDialog(true);
        setEdata(data);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    //#endregion

    return (
        <>
            <EPopup handleClose={handleClose} open={openDialog} edata={edata} />
            {listAll && (<AllInvoices handleClickOpen={handleDialogOpen} handleClose={handleDialogClose} open={listAll} setOpen={setListAll} />)}
            <Container>
                <InvoiceCard editData={editData} setEditData={setEditData} setIsChanged={setIsChanged} isChanged={isChanged} />

                <InvoiceTable handleEditData={handleEditData} isChanged={isChanged} handleClickOpen={handleClickOpen} handleDialogOpen={handleDialogOpen} />
            </Container>
        </>
    )
}
export default Invoice;