import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import configServ from '../../services/config';

//FUNCTION
const CustomUserTable = ({ handleEdit, isChanged, pagination = true }) => {
  //#region code
  const { company_id, user_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [10, 20, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [userList, setUserList] = useState([]);
  const [filterUserList, setFilterUserList] = useState([]);
  const [searchBy, setSearchBy] = useState('fname');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const userTableData = async() =>{
      await fetchRoles();
      await fetchUserList();
    }
    userTableData();
  }, [isChanged]);

  const fetchRoles = async () => {
    try {
        const result = await configServ.getRoles({ "admin_id": user_id })
        if (result.status === 200) {
            setRoleList(result.data);
        }
        else {
            setRoleList([]);
        }
    }
    catch (error) {
        console.log(error);
    }
  }

  const fetchUserList = async () => {
    try {
      const result = await configServ.getAccessMasterUser({ "company_id": company_id })
      if (result.status === 200) {
        setUserList(result.data);
        setFilterUserList(result.data);
        setCount(result.data.length);
      }
      else {
        setUserList([]);
        setFilterUserList([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const headers = ['USER NAME', 'EMAIL ID', 'MOBILE NO.', 'ROLE', 'STATUS', 'ACTION'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? filterUserList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filterUserList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5',
    color: 'white',
  };
  
  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === '') {
        data = userList;
      }
      else if (searchBy === 'fname') {
        data = userList.filter(x => x.fname.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'user_code') {
        data = userList.filter(x => x.item_code.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'email') {
        data = userList.filter(x => x.email.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'mobile') {
        data = userList.filter(x => x.mobile.toLowerCase().includes(search.toLowerCase()))
      }
      setFilterUserList(data);
      setCount(data.length);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleRole = (param) => {
    try{
      if(param !== null && param !== "" && param !== undefined){
        const roleData = roleList.filter(x=>x.id === param.role)[0];
        return roleData.name;
      }
      else{
        return "N/A";
      }
    }
    catch(e){
      console.log(e);
    }
  }
  
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    )
  }
  else {
    return (
      <>
        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>User List: {count}</Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
              <FormControl
                fullWidth
              >
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={'fname'}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: 'searchBy',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'fname'}>User Name</option>
                  <option value={'user_code'}>User Code</option>
                  <option value={'email'}>Email</option>
                  <option value={'mobile'}>Mobile</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: 'grey' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} elevation={0}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, rowIndex) => (
                <TableRow key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell>{row.fname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{handleRole(row.user)}</TableCell>
                  <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                  <TableCell>
                    <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                      onClick={() => { handleEdit(row); scrollToTop() }}
                    >
                      <Tooltip title='Edit' arrow>
                        <EditIcon color='primary' />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </>
    );
  }
};

export default CustomUserTable;
