import storage from 'redux-persist/lib/storage';
import { encryptTransformConfig } from './encryption';

const persistConfig = {
  key: 'dcrm',
  storage,
  transforms: [encryptTransformConfig],
  whitelist: ['sideExpand', 'user', 'cart', 'admin', 'GlobalVariables'], 
  // Other configuration options can be added here
};

export default persistConfig;
