import { Box, Divider, Stack, TextField, Typography, FormControlLabel, Checkbox, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { assignBillToAddress, assignShipToAddress } from "../../../redux/reducers/Cart";
import { useEffect } from "react";

const CustAddress = ({ handleNext }) => {
    //#region code
    const dispatch = useDispatch();
    const { address } = useSelector((state) => state.GlobalVariables);
    const { billtoaddress, shiptoaddress } = useSelector((state) => state.cart)

    const billTo = () => {
        dispatch(assignBillToAddress(address))
        dispatch(assignShipToAddress(''))
    }
    const shipToSameAsBillTo = (e) => {
        const { checked } = e.target
        // console.log(checked)
        if (checked) {
            dispatch(assignShipToAddress(billtoaddress))
        } else {
            dispatch(assignShipToAddress(''))
        }
    }
    const handleBillTo = (e) => {
        const { value } = e.target
        dispatch(assignBillToAddress(value))
    }
    const handleShipTo = (e) => {
        const { value } = e.target
        dispatch(assignShipToAddress(value))
    }

    useEffect(() => {
        billTo()
    }, []);
    //#endregion

    return (
        <>
            <Typography variant="h6">Verify Delivery Address</Typography>
            <br />
            <Box sx={{ width: 'auto' }}>
                <Typography variant="body" fontWeight='bold'>Bill to address</Typography>
                {/* <Divider sx={{bgcolor:'#333', width:'50%'}}/> */}
                <Stack direction='column' justifyContent='center' alignItems='left' sx={{ width: { sx: '100%', md: '50%' }, height: 'fit-content' }}>
                    <TextField
                        placeholder="Address"
                        size="small"
                        value={billtoaddress}
                        onChange={handleBillTo}
                    />
                </Stack>
                <br />
                <Typography variant="body" fontWeight='bold'>Ship to address ( <FormControlLabel control={<Checkbox onChange={shipToSameAsBillTo} size="small" />} label="Same as Bill to address" />)</Typography>
                {/* <Divider sx={{bgcolor:'#333', width:'50%'}}/> */}
                <Stack direction='column' justifyContent='center' alignItems='left' sx={{ width: { sx: '100%', md: '50%' }, height: 'fit-content' }}>
                    <TextField
                        placeholder="Address"
                        size="small"
                        value={shiptoaddress}
                        onChange={handleShipTo}
                    />

                    <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                            mt: 3,
                            mb: 2,
                            width: "200px",
                            minWidth: '200px',
                            ":hover": {
                                backgroundColor: 'black'
                            },
                            alignSelf: {
                                xs: 'center',
                                md: 'flex-start'
                            }
                        }}
                    >
                        Confirm Address
                    </Button>
                </Stack>

            </Box>
        </>
    )
}

export default CustAddress;
