
import { useJwt } from 'react-jwt'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { rSetLogin } from '../../redux/reducers/GlobalVariables';

export const SessionVerification = () => {
    //#region code
    //const { user_id } = useSelector((state) => state.GlobalVariables);
    // const token = localStorage.getItem("token");
    // const { isExpired } = useJwt(token);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const cookie = new Cookies()

    // Get the value of the cookie named 'dcrm_token'
    const cook = cookie.get('dcrm_token');

    // Check if the cookie exists
    if (cook === undefined) {
        localStorage.setItem('session','expired');
        dispatch(rSetLogin(false));
        navigate('/login');
        //history.push('/admin-login', { message: 'Unauthorized access' });
    }

    // if (isExpired) {
    //     navigate('/');
    // }
    // if (cookie.get('dcrm_token') === undefined) {
    //     navigate('/');
    // }
    // if (token === undefined) {
    //     navigate('/');
    // }
    // if (user_id === undefined) {
    //     navigate('/');
    // }
}
