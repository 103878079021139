import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { NavLink, useNavigate } from 'react-router-dom';

//Icon Imports
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PasswordIcon from '@mui/icons-material/Password';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import InventoryIcon from '@mui/icons-material/Inventory';

// import SiteLogo from '../Assets/images/DovetailLogo.svg'
import SiteLogo from '../Assets/images/siteLogoWhite.svg'

import { useSelector, useDispatch } from 'react-redux';
import { trigger } from '../../redux/reducers/SideExpand';
import MenuListCard from '../MenuListCard/MenuListCard';
import Logout from '../Logout/Logout';

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);



export default function CustomerSideBar() {

    const dispatch = useDispatch()
    const open = useSelector((state) => state.sideExpand.open)
    const { company_type } = useSelector((state) => state.admin);

    // const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);
    const [isLogutDialogOpen, setIsLogutDialogOpen] = React.useState(false);
    // const [selectedMenu, setSelectedMenu] = React.useState(0);
    // const [hidden, setHidden] = React.useState(false);


    const handleDrawerClose = () => {
        // setOpen(false);
        dispatch(trigger())
    };


    //   const handleExpand = (number)=>{
    //     setSelectedMenu(number)
    //     setIsMenuExpanded(!isMenuExpanded)
    //   }


    //logout
    const handleLogout = () => {
        setIsLogutDialogOpen(!isLogutDialogOpen)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
            {/* <AppBar position="fixed" open={open} sx={{backgroundColor:'white', zIndex:-1}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
              color:'black'
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton>
            <img src={SiteLogoInitial} alt="logoInitial" width='50' />
          </IconButton>
        </Toolbar>
      </AppBar> */}
            <Drawer
                variant="permanent"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "#333",
                    }
                }}
            >
                <DrawerHeader>
                    <img src={SiteLogo} alt="logo" width='150' hidden={open ? false : true} />
                    <IconButton onClick={handleDrawerClose}>
                        {!open ? <MenuIcon sx={{color:'white'}} /> : <ChevronLeftIcon sx={{color:'white'}} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <NavLink to='/customer-dashboard' style={{ textDecoration: 'none', color: '#FFF4F4' }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <DashboardIcon sx={{color:'white'}} />
                            </ListItemIcon>
                            <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>

                <Divider variant='middle' sx={{
                    backgroundColor: '#6270e488',
                    marginBottom: 2,
                }} />
                {/* <List>
          {['Master Entry', 'Customers/Ledgers', 'Invoices', 'Sales Order'].map((text, index) => (
            <ListItem number={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}



                <MenuListCard url='/customer-invoice' number={1} text={'Invoice'} icon={<ReceiptIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/product' number={1} text={company_type === 'service' ? 'Services' : 'Products'} icon={<InventoryIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/Purchase-Order' number={1} text={'Purchase Order'} icon={<ReceiptIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard url='/customer-payment' number={2} text={'Payment'} icon={<AccountBalanceIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <div hidden={company_type === 'product'}>
                    <MenuListCard url='/customer-sales-subscription' number={1} text={'Sales Subscription'} icon={<AssessmentIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                </div>
                <MenuListCard number={3} url='/customer-profile' text={'Profile'} icon={<AccountBoxIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard number={4} url='/customer-change-password' text={'Change Password'} icon={<PasswordIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <MenuListCard logout={handleLogout} number={5} text={'Log Out'} icon={<LogoutIcon sx={{color:'white'}} />} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} />
                <Divider />
            </Drawer>

        </Box>
    );
}