import React, { memo, useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    Grid,
    Divider,
} from '@mui/material'
import { motion } from 'framer-motion';
import ButtonMUI from '@mui/material/Button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CustomUserTable from './CustomUserTable';
import configServ from '../../services/config';
import UserContact from '../AccessMaster/UserContact';
import AlertDialog from '../AlertDialog/AlertDialog';

//FUNCTION
const CustomUser = ({role}) => {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);

    const initialFormData = {
        openingamount: 0,
        status: true,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openCustContact, setOpenCustContact] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent("");
    };

    const handleEdit = (data) => {
        setEditData(data);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked,
        }));
    };

    const assignContactName = () => {
        if (!isDone) {
            setFormData((state) => ({
                ...state,
                contact_name: formData.fname || "",
            }));
        }
    };

    const handleSubmit = async () => {
        const dataToSend = {
            ...formData,
            company_id: company_id,
            user_id: user_id,
        };
        if (formData.is_credit_days === false) {
            delete dataToSend.credit_period;
            delete dataToSend.credit_limit;
        }
        try {
            const error = handleFormValidation();
            if (error === false) {
                return false;
            }
            setValidationErrors({});
            if (!isEdit) {
                const result = await configServ.createAccessMasterUser(dataToSend);
                if (result.status === 200) {
                    setFormData(initialFormData);
                    setIsChanged(!isChanged);
                    handleOpenDialog(result.message);
                }
                clear();
            }
            else {
                const result = await configServ.updateAccessMasterUser(dataToSend);
                if(result.status === 200){
                    setIsChanged(!isChanged);
                    handleOpenDialog(result.message);
                }
                clear();
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const fetchCounties = async () => {
        try {
          const res = await configServ.getCountry();
          if (res.status === 200) {
            setCountryList(res.data);
          }
          else {
            setCountryList([]);
          }
        }
        catch (e) {
          console.log(e);
        }
      };
    
      useEffect(() => {
        if (formData.country) {
          fetchStates();
        }
      }, [formData.country]);
    
      const fetchStates = async () => {
        try {
          const countryId = countryList.find(x => x.country === formData.country).PK;
          const res = await configServ.getState({ "country": countryId });
          if (res.status === 200) {
            setStateList(res.data);
          }
          else {
            setStateList([]);
          }
        }
        catch (e) {
          console.log(e);
        }
      };

    const fetchRoles = async () => {
        try {
            const result = await configServ.getRoles({ "admin_id": user_id })
            if (result.status === 200) {
                setRoleList(result.data);
            }
            else {
                setRoleList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCounties();
        fetchRoles();
    }, []);

    // useEffect(()=>{
    //     if(role !== "" && roleList.length > 0){
    //         const selectedRole = roleList.filter(x=>x.name.toLowerCase().includes(role.toLowerCase()))[0];
    //         setFormData((prev)=>({
    //             ...prev,
    //             'role': selectedRole.id
    //         }));
    //     }
    // },[roleList][role]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                ...editData,
                role: editData.user.roles[0]?.id || null,
            }));
            setIsEdit(true);
        }
    }, [editData]);

    const clear = () => {
        setFormData(initialFormData);
        setEditData({});
        setIsDone(false);
        setIsEdit(false);
        setValidationErrors({});
    };

    const handleCustContactOpen = () => {
        setOpenCustContact(true);
    };

    const handleCustContactClose = () => {
        setOpenCustContact(false);
    };

    const handleFormValidation = () => {
        const errors = {};
        if (!formData.fname) {
            errors.fname = "required";
        }
        if (!formData.contact_name) {
            errors.contact_name = "required";
        }
        if (!formData.mobile) {
            errors.mobile = "required";
        }
        if (!formData.address) {
            errors.address = "required";
        }
        if (!formData.country) {
            errors.country = "required";
        }
        if (!formData.State) {
            errors.State = "required";
        }
        if (!formData.Pin) {
            errors.Pin = "required";
        }
        if (!formData.type) {
            errors.type = "required";
        }
        if (!formData.openingamount) {
            errors.openingamount = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <UserContact
                handleClose={handleCustContactClose}
                open={openCustContact}
                customer_id={formData.id}
                customer_code={formData.customer_code}
            />

            <motion.div
                style={{
                    width: "100%",
                    backgroundColor: "#e3f3fc",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: "10px",
                    border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Divider sx={{my:1}}><b>{role === "" || role === null? "":role.toUpperCase() } DETAIL</b></Divider>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    {/* <Grid container justifyContent="space-between" alignItems="center" spacing={1}> */}
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Firm Name*"
                                name="fname"
                                onChange={handleChange}
                                value={formData.fname || ""}
                                error={!!validationErrors.fname}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Contact Name*"
                                name="contact_name"
                                onChange={handleChange}
                                onClick={() => {
                                    assignContactName();
                                    setIsDone(true);
                                }}
                                value={formData.contact_name || ""}
                                error={!!validationErrors.contact_name}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Mobile Number*"
                                name="mobile"
                                type="number"
                                onChange={handleChange}
                                value={formData.mobile || ""}
                                error={!!validationErrors.mobile}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                name="email"
                                onChange={handleChange}
                                value={formData.email || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Bill to Address*"
                                name="address"
                                onChange={handleChange}
                                value={formData.address || ""}
                                error={!!validationErrors.address}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Country*</InputLabel>
                                <Select
                                    required
                                    name="country"
                                    l
                                    abel="select"
                                    value={formData.country || ""}
                                    onChange={handleChange}
                                    error={!!validationErrors.country}
                                    sx={{ backgroundColor: "white" }}

                                >
                                    {countryList.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.country}>
                                                {item.country}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel>State*</InputLabel>
                                <Select
                                    required
                                    name="State"
                                    label="select"
                                    value={formData.State || ""}
                                    onChange={handleChange}
                                    error={!!validationErrors.State}
                                    sx={{ backgroundColor: "white" }}

                                >
                                    {stateList.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.State}>
                                                {item.State}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                label="Pincode*"
                                name="Pin"
                                onChange={handleChange}
                                value={formData.Pin || ""}
                                error={!!validationErrors.Pin}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="user-type">User Type*</InputLabel>
                                <Select
                                    labelId="user-type"
                                    label="User Type"
                                    name="type"
                                    value={formData.type || ""}
                                    onChange={handleChange}
                                    error={!!validationErrors.type}
                                    sx={{ backgroundColor: "white" }}

                                >
                                    <MenuItem value={"Standard"}>Standard </MenuItem>
                                    <MenuItem value={"Previleged"}>Previleged</MenuItem>
                                    <MenuItem value={"Staff"}>Staff</MenuItem>
                                    <MenuItem value={"Discount"}>Discount </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                label="Opening Amount*"
                                name="openingamount"
                                onChange={handleChange}
                                value={formData.openingamount || ""}
                                defaultValue={0}
                                error={!!validationErrors.openingamount}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="PAN/NO"
                                name="pan_no"
                                onChange={handleChange}
                                value={formData.pan_no || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label="GSTIN/UIN"
                                name="gstin"
                                onChange={handleChange}
                                value={formData.gstin || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                label="Credit Period"
                                size="small"
                                name="credit_period"
                                InputProps={{
                                    endAdornment: "d",
                                }}
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_period || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <TextField
                                fullWidth
                                label="Credit Limit"
                                size="small"
                                name="credit_limit"
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_limit || ""}
                                sx={{ backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel htmlFor="role">Role*</InputLabel>
                                <Select
                                    value={formData.role || ""}
                                    onChange={handleChange}
                                    label="Role*"
                                    name='role'
                                    inputProps={{
                                        name: 'role',
                                        id: 'role',
                                    }}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                    {roleList.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <ButtonMUI
                                variant="outlined"
                                color="secondary"
                                size="small"
                                hidden={isEdit ? false : true}
                                onClick={handleCustContactOpen}
                            >
                                Add Contact Details
                            </ButtonMUI>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControlLabel
                                control={<Checkbox />}
                                name="is_credit_days"
                                checked={Boolean(formData.is_credit_days)}
                                onChange={handleChangeCheckbox}
                                label="Check credit days" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} columnSpacing={0}>
                            <FormControlLabel
                                control={<Checkbox />}
                                name="status"
                                checked={Boolean(formData.status)}
                                onChange={handleChangeCheckbox}
                                label="Status"
                                sx={{ fontWeight: "bold" }} />
                        </Grid>

                        <Grid container spacing={2} justifyContent="space-between" px={2}>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        onClick={clear}
                                        variant="contained"
                                        color='secondary'
                                        startIcon={<RotateLeftIcon />}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleSubmit}
                                        autoFocus
                                        variant="contained"
                                        color='primary'
                                        startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                    >
                                        {isEdit ? "Update" : "Save"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    {/* </Grid> */}
                </Grid>
            </motion.div>

            <CustomUserTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}

export default memo(CustomUser);
