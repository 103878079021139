import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Select,
    TextField,
    Autocomplete,
} from "@mui/material";
import { motion } from "framer-motion";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import Calculation from '../../../utility/Calculations';
import configServ from '../../../services/config';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';
import AlertDialog from '../../AlertDialog/AlertDialog';
import AdminSubHeader from '../../header/subHeader/AdminSubHeader';

//FUNCTION
const DispatchCard = ({ editData, setEditData, isChanged, setIsChanged }) => {
    //#region code
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);

    const formDataInitial = {
        Date: Calculation.GetCurrentDate()
    }
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    useEffect(() => {
        fetchInvoice();
    }, []);

    useEffect(()=>{
        if(editData !== null){
            setFormData(editData);
        }
    },[editData]);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const handlePopup = () => {
        try {
            if (isCreate === true) {
                handleReset();
            }
            setIsCreate(!isCreate);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormData = (event) => {
        try {
            const { name, value } = event.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchInvoice = async () => {
        try {
            const dataToSend = {
                company_id: company_id
            }
            const res = await configServ.getInvoiceByCompany(dataToSend);
            if (res.status === 200) {
                setInvoices(res.data);
            }
            else {
                setInvoices([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.invoice_no) {
                errors.invoice_no = 'required.'
            }
            if (!formData.transporter_name) {
                errors.transporter_name = 'required.'
            }
            if (!formData.TransporterMobileNo) {
                errors.TransporterMobileNo = 'required.'
            }
            if (!formData.qnty) {
                errors.qnty = 'required.'
            }
            if (!formData.amount) {
                errors.amount = 'required.'
            }
            if(!formData.Date){
                errors.Date = 'required';
            }
            setValidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            dispatch(triggerLoader());
            e.preventDefault();
            const isValid = await validate();
            if (isValid === false) {
                return false;
            }
            const dataToSend = {
                ...formData,
                company_id: company_id
            }
            if (!isEdit) {
                const res = await configServ.createDispatch(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog('Success', res.message || "Dispatched successfully.", 'success');
                    handleReset()
                }
                else {
                    handleOpenDialog('Error', res.message || "Dispatch failed. Please try again.", 'error');
                }
            }
            else {
                const res = await configServ.updateDispatch(dataToSend); 
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog('Success', res.message || "Dispatched updated successfully.", 'success');
                    handleReset()
                }
                else {
                    handleOpenDialog('Error', res.message || "Dispatch failed. Please try again.", 'error');
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData(formDataInitial);
            setValidationErrors({});
            setEditData(null);
            setIsEdit(false);
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <AdminSubHeader
                prefix={"Inv"}
                suffix={"Order"}
                title={"DISPATCH"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {isCreate === true && (
                <>
                    <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: 0.1 }}
                    >
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                sx={{ p: 0, }}
                                spacing={1}
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        size='small'
                                        id="combo-box-demo"
                                        name='invoice_no'
                                        options={invoices.map((option) => option.invoice_number)}
                                        sx={{ background: 'white' }}
                                        value={formData.invoice_no}
                                        onChange={(event, newValue) => handleFormData({ target: { name: 'invoice_no', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Invoice No" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transporter Name'}
                                        name='transporter_name'
                                        value={formData.transporter_name || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.transporter_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Phone'}
                                        name='TransporterMobileNo'
                                        value={formData.TransporterMobileNo || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.TransporterMobileNo}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Vehicle No'}
                                        name='vehicle_num'
                                        value={formData.vehicle_num || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Quantity'}
                                        name='qnty'
                                        value={formData.qnty || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.qnty}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Amount'}
                                        name='amount'
                                        value={formData.amount || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transportation Cost'}
                                        name='transportationCost'
                                        value={formData.transportationCost || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Vehicle No'}
                                        name='vehicle_num'
                                        value={formData.vehicle_num || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='date'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Dispatch Date'}
                                        name='Date'
                                        InputLabelProps={{shrink:true}}
                                        value={formData.Date || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.Date}
                                    />
                                </Grid>
                                <Grid container item xs={12}>
                                    {/* <Grid container item xs={12} md={6} justifyContent={'flex-start'}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            name="status"
                                            checked={Boolean(formData.status)}
                                            onChange={handleChangeCheckbox}
                                            label="Status"
                                            sx={{ fontWeight: "bold" }}
                                        />
                                    </Grid> */}
                                    <Grid container item xs={12} md={12} justifyContent={{xs: 'center', md:'flex-end'}}>
                                        <Button
                                            type='reset'
                                            color={'secondary'}
                                            startIcon={<RotateLeftIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type='submit'
                                            color={isEdit ? 'success' : 'primary'}
                                            startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            {isEdit ? 'Update' : 'Save'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </motion.div>
                </>
            )}
        </>
    )
}

export default memo(DispatchCard);
