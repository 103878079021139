import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { CartItem } from "./CartItem";
import configServ from "../../../services/config";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { clear } from '../../../redux/reducers/Cart'
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";

const Order = () => {
    //#region code
    const { user_id, company_id, currency } = useSelector((state) => state.GlobalVariables);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const navigate = useNavigate()

    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        return `${year}-${month}-${day}`
    }

    const generateOrderNumber = () => {
        const min = 100000;
        const max = 999999;
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return `ORD${number}`
    };

    const fetchUserName = async () => {
        const id = {
            "user_id": user_id
        }
        if (id.user_id !== undefined) {
            // const res = await http.post('/api/profiledetail',id )
            const res = await configServ.profiledetail(id)
            if (res.success) {
                return res.success.name
            } else {
                console.error('Error while fetchiing user data')
            }
        } else {
            console.log('There is a login error, please login again')
        }
    }


    const itemAssign = async () => {
        try {
            const selectedItemsArray = cart.cartValue.map((item) => ({
                "company_id": company_id,
                "itemname": item.itemname,
                "hsn_code": item.hsn_code,
                "Taxrate": item.Taxrate,
                "qty": item.qty,
                "rate": item.rate,
                "amount": item.qty * item.rate,
                "itemid": item.id,
                "item_code": item.item_code,
                "size_id": item.size_id,
                "issizeAdded": item.issizeAdded,
                "item_size": item.item_size
            }))
            return selectedItemsArray
        } catch (e) {
            console.log(e)
        }
    }

    const handleOrder = async (e) => {
        e.preventDefault()
        const dataToSend = {
            "order_no": generateOrderNumber(),
            "customer_id": user_id,
            "company_id": company_id,
            "order_date": getCurrentDate(),
            "partyname": await fetchUserName(),
            // "partyledgername": cart.cartValue.itemname,
            "shiptoaddress": cart.shiptoaddress,
            "billtoaddress": cart.billtoaddress,
            "total_amount": cart.cartAmount,
            "IsApproved": 0,
            "is_sync_to_tally": 0,
            "order_type": "Primary",
            "item": []
        }
        dataToSend.item = await itemAssign();
        try {
            const orderResult = await configServ.placeOrder(dataToSend)
            console.log(orderResult)
            handleOpenDialog('Your order has successfully placed')
            navigate('/Purchase-Order')
            dispatch(clear())
        }
        catch (e) {
            console.log(e)
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            {/* <Button onClick={abc}>ABC</Button> */}
            <Typography variant="h6">Order Confirmation</Typography>
            <br />
            <Stack direction={{ xs: 'column', md: 'column' }} spacing={1}>

                <Typography variant="body" fontWeight='bold'>Bill to address</Typography>
                <Divider sx={{ bgcolor: '#999', width: '50%' }} />
                <Typography variant="body2" color='text'>{cart.billtoaddress}</Typography>
                <br />
                <Typography variant="body" fontWeight='bold'>Ship to address</Typography>
                <Divider sx={{ bgcolor: '#999', width: '50%' }} />
                <Typography variant="body2" color='text'>{cart.shiptoaddress}</Typography>
                <br />
                <Typography variant="body" fontWeight='bold'>Product(s)</Typography>
                <Divider sx={{ bgcolor: '#999', width: '50%' }} />
                {
                    cart.cartValue.map((item) => (
                        <Stack key={item.id} direction='row'>
                            <Typography variant="body2">{item.itemname}   <span style={{ fontWeight: 'bold' }}>x  {item.qty}</span></Typography>
                        </Stack>
                    ))
                }
                <br />
                <Typography variant="body" fontWeight='bold'>Total Amount</Typography>
                <Divider sx={{ bgcolor: '#999', width: '50%' }} />
                <Typography variant="body2" color='text'>{currency} {cart.cartAmount}</Typography>
                <br />
                <Button
                    variant="contained"
                    onClick={handleOrder}
                    sx={{
                        mt: 3,
                        mb: 2,
                        width: "200px",
                        minWidth: '200px',
                        ":hover": {
                            backgroundColor: 'black'
                        },
                        alignSelf: {
                            xs: 'center',
                            md: 'flex-start'
                        }
                    }}
                >
                    Confirm Order
                </Button>


            </Stack>


        </>
    )
}
export default Order;