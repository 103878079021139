import React,{useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Typography } from '@mui/material'; 
import AddIcon from '@mui/icons-material/Add';
import MiniLoader from '../MiniLoader/MiniLoader';

import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import PaymentIcon from '@mui/icons-material/Payment';

// import NestedTable from '../../../pages/Invoice/NestedTable';
// import CustNestedTable from './CustNestedTable';
import {motion, AnimatePresence} from 'framer-motion'



// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }


export default function CustPaymentTable({tableData, miniLoader}) {
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[1]);
  const [expand, setExpand] = React.useState(false);
  const [expandId, setExpandId] = React.useState(0);

  const handleExpand = (id)=>{
    setExpand(!expand)
    setExpandId(id)
  }

useEffect(()=>{
    console.log(tableData)
},[])


  const columns = [
    { id: 'id', label: 'Sr. No.', minWidth: 170 },
      {
        id: 'voucher_no',
        label: 'Voucher Number',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
    {
      id: 'date',
      label: `Date`,
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'transaction_type',
      label: 'Transaction Type',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'bank',
        label: 'Bank Name',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
      },    
    {
      id: 'amount',
      label: 'Amount',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}
          component={motion.div}
          initial={{opacity:0,height:0}}
          animate={{opacity:1,height:440}}
          transition={{duration:0.3, delay:0.2}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column,index) => (
                <TableCell
                  key={column.id}
                //   align={column.align}
                //   style={{ top: 0, minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,index) => {
                return (
                    <>
                  <TableRow key={row.id} hover role="checkbox" tabIndex={-1} >
                        <TableCell sx={{minWidth:100}}><AddIcon onClick={()=>{handleExpand(row.id)}} color='primary' sx={{mr:'20px',transition:'100ms', cursor:'pointer',":hover":{transform:'scale(1.15)'}}}/>{++index}</TableCell>
                        <TableCell >{row.voucher_no}</TableCell>
                        <TableCell >{row.date}</TableCell>
                        <TableCell >{row.voucher_type}</TableCell>
                        <TableCell >{row.total_amount}</TableCell>
                        <TableCell >{row.amount}</TableCell>              
                  </TableRow>
                  {/* <AnimatePresence>
                  {(expand && expandId === row.id) && (<TableRow 

                  >
                    <TableCell colSpan={9}>
                        <Stack justifyContent='center' alignItems='center' >
                            <CustNestedTable data={row}/>
                        </Stack>
                  </TableCell>
                  </TableRow>)}
                  </AnimatePresence> */}
                  {/* <NestedTable/> */}
                  </>
                );
              })}
                  {tableData.length === 0 && (<TableRow>
                    <TableCell align='center' colSpan={6}><Typography variant='h6'>No Records to show </Typography></TableCell>
                  </TableRow> )}   
                  {miniLoader && (<TableRow>
                        <TableCell align='center' colSpan={8}><MiniLoader/></TableCell>
                    </TableRow>)}         
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
    </Paper>
    </>
  );
}