import React, { useState, useEffect, memo } from "react";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import { Checkbox, FormControlLabel, FormLabel, Grid, TextField, Button, Box } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AlertDialog from "../../AlertDialog/AlertDialog";

function ConfigurationCard({ isEdit, setIsEdit, isChanged, setIsChanged, editData, setEditData }) {
  //#region code
  const { company_id, user_id, companyName } = useSelector((state) => state.GlobalVariables);
  const [validationErrors, setValidationErrors] = useState({});
  const initialFormData = {
    mail_to: "",
    mail_cc: "",
    message: "",
    invoice_notification: false,
    sale_notification: false,
    payment_notification: false,
    ewaybill_notification: false,
    status: true,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [title, setTitle] = useState('Alert');
  const [buttonType, setButtonType] = useState(null);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    //Update Validation
    if (validationErrors[name]) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData(editData);
    }
  }, [editData])

  const handleSave = async () => {
    try {
      const error = await handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});

      // Prepare the data object with "TO" and "CC" fields along with checkbox values
      const requestData = {
        id: formData.id,
        company_id: company_id,
        company_name: companyName,
        user_id: user_id,
        mail_to: formData.mail_to,
        mail_cc: formData.mail_cc,
        message: formData.message,
        invoice_notification: formData.invoice_notification,
        sale_notification: formData.sale_notification,
        payment_notification: formData.payment_notification,
        status: formData.status,
      };

      if (!isEdit) {
        const res = await configServ.addMailConfiguration(requestData);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          handleReset();
          handleOpenDialog('Success', res.message, 'success');
        }
        else {
          handleOpenDialog('Warning', res.message || "Failed to create mail configuration.", 'warning');
        }
      }
      else {
        const res = await configServ.editMailConfigurations(requestData);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          handleReset();
          handleOpenDialog('Success', res.message, 'success');
        }
        else {
          handleOpenDialog('Warning', res.message || "Failed to update mail configuration.", 'warning');
        }
      }
    }
    catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleFormValidation = async () => {
    const errors = {};
    if (!formData.mail_to) {
      errors.mail_to = "required";
    }
    if (!formData.mail_cc) {
      errors.mail_cc = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setValidationErrors({});
    setIsEdit(false);
    setEditData({});
  }

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
};

const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
};
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <Box style={{ width: '100%', backgroundColor: '#e3f3fc', padding: 20, border: 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormLabel error={!!validationErrors.mail_to}>TO*</FormLabel>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              placeholder="Enter email address"
              sx={{ backgroundColor: 'white' }}
              name="mail_to"
              value={formData.mail_to}
              onChange={handleInputChange}
              error={!!validationErrors.mail_to}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel error={!!validationErrors.mail_cc}>CC*</FormLabel>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              placeholder="Enter email address"
              sx={{ backgroundColor: 'white' }}
              name="mail_cc"
              value={formData.mail_cc}
              onChange={handleInputChange}
              error={!!validationErrors.mail_cc}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormLabel>Message</FormLabel>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              multiline
              rows={4}
              placeholder="Enter message"
              sx={{ backgroundColor: 'white' }}
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.invoice_notification}
                  onChange={handleInputChange}
                  name="invoice_notification"
                />
              }
              label="Invoice Notification"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.sale_notification}
                  onChange={handleInputChange}
                  name="sale_notification"
                />
              }
              label="Sale Notification"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.payment_notification}
                  onChange={handleInputChange}
                  name="payment_notification"
                />
              }
              label="Payment Notification"
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.ewaybill_notification}
                  onChange={handleInputChange}
                  name="ewaybill_notification"
                />
              }
              label="E-Way Bill"
            />
          </Grid> */}

          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.status}
                  onChange={handleInputChange}
                  name="status"
                />
              }
              label="Status"
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />} sx={{ margin: '10px' }}>
              {isEdit ? 'Update' : 'Save'}
            </Button>
            <Button variant="contained" color="secondary" onClick={handleReset} startIcon={<RestartAltIcon />} sx={{ margin: '10px' }}>
              Reset
            </Button>
          </Grid>

        </Grid>
      </Box>
    </>
  );
}

export default ConfigurationCard;
