import React,{useEffect, useState} from "react";
// import MasterEnteryCard from '../../Components/Card/MaterEntryCards/MasterEntryCard'
// import TableCard from "../../Components/Card/MaterEntryCards/TableCard";


import { Form, Container, Row, Col } from 'react-bootstrap';

import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";

import CreateReceiptsCard from "../../../Components/Card/TransactionCards/CreateReceipts/CreateReceiptsCard";
import CreateReceiptsTable from "../../../Components/Card/TransactionCards/CreateReceipts/CreateReceiptsTable";
import SrsTable from "../../../Components/Card/ReportCards/Srs/SrsTable";

import ReportCard from "../../../Components/Card/ReportCards/ReportCard";

 const SRS = () =>{


    // useEffect(()=>{
    //     forLoop()
    // })

    return(
        <>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb:5
                        }}
                    >
                            <h4 style={{ textAlign: 'center' }} >Sales Report Summary</h4>
                            <br/>
                        
                <Container>
                    <ReportCard title={'Credit'}/>
                </Container>


                        <Divider />
                        <br />
                        <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                            <Typography fontSize={15} variant="body" fontWeight='bold'>Payment Receipts List</Typography>
                            <Divider/>
                        </Stack>
                        <SrsTable/>
                    </Paper>
                </Stack>

        </>
    )
 }
 export default SRS