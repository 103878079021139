import React, { useState, useEffect } from "react";
// import { Form, Row, Col, FormCheck, Button} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import './Login.css'
import http from "../../services/http";
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Cookies from "universal-cookie/cjs/Cookies";
import { decodeToken } from 'react-jwt'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import LoginIcon from '@mui/icons-material/Login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Box, Typography, Container, TextField, FormControlLabel, Grid, Checkbox, Button, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import configServ from "../../services/config";
import { assignType } from "../../redux/reducers/Admin";
import { rSetAddress, rSetCompanyId, rSetCompanyName, rSetCurrency, rSetEmail, rSetId, rSetLogin, rSetName, rSetToken, rSetUserId, rSetUserName } from "../../redux/reducers/GlobalVariables";

const Login = () => {
    //#region code
    const lightMode = useSelector(state => state.GlobalVariables.lightMode);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const { isUserAdmin } = useSelector((state) => state.user);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setLoginError(true);
    };

    const handleCloseDialog = () => {
        setLoginError(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const logout = () => {
        //persistor.purge(); // This will remove all data persisted by redux-persist
        localStorage.removeItem('persist:dcrm')
        localStorage.clear();
    };

    useEffect(() => {
        if (localStorage.getItem('session') === 'expired') {
            logout();
            handleOpenDialog('Session Expired', 'Session expired. Please login to continue', 'error');
        }
    }, []);

    // to fetch data from text box
    const handleChange = (e) => {
        const { name, value } = e.target;

        //Update form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }
    }

    const validate = async () => {
        const errors = {};
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.password) {
            errors.password = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    // to handle login
    const handleLogin = async (e) => {
        e.preventDefault();
        const isValid = await validate();
        if (isValid === false) {
            return false;
        }
        dispatch(triggerLoader())
        try {
            const result = await configServ.login(formData);
            if (result.success) {
                dispatch(rSetLogin(true));
                localStorage.setItem("dcrm_token", result.success.token);

                const oneHour = 12* 60 * 60 * 1000; // 12 hour in milliseconds
                const expirationDate = new Date(Date.now() + oneHour);
                cookie.set('dcrm_token', result.success.token, {
                    //expires: new Date(decodedToken.exp * 1000)
                    expires: expirationDate

                });
                cookie.set('userType', 'admin', {
                    //expires: new Date(decodedToken.exp * 1000)
                    expires: expirationDate
                });

                dispatch(rSetEmail(result.success.email));
                dispatch(rSetUserId(result.success.customerId));
                dispatch(assignType(result.success.company_type));
                dispatch(rSetCurrency(result.success.currency));
                dispatch(rSetCompanyId(result.success.company_id));
                // const data = {
                //     id: result.success.company_id,
                // }
                // const res = await configServ.getCompanyById(data);
                // if (res.status === 200) {
                //     dispatch(rSetCompanyName(res.data.CompanyName));
                // }
                dispatch(rSetCompanyName(result.success.company_name));
                dispatch(rSetId(result.success.id));
                dispatch(rSetUserName(result.success.name));
                dispatch(rSetName(result.success.name));
                dispatch(rSetAddress(result.success.address));

                navigate('/customer-dashboard', { replace: true })
            }
            else {
                handleOpenDialog('Error', 'Invalid Login credentials', 'error');
            }
        }
        catch (err) {
            console.log(err)
            handleOpenDialog('Error', 'Invalid Login credentials', 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    // const checkedLoggedIn = () => {
    //     if (cookie.get('dcrm_token') !== undefined) {
    //         if (cookie.get('userType') === 'customer') {
    //             navigate('/customer-dashboard')
    //         }
    //         else {
    //             navigate('/Dashboard')
    //         }
    //     }
    // }

    // useEffect(() => {
    //     checkedLoggedIn()
    // }, []);
    //#endregion

    return (
        <>
            <AlertDialog
                open={loginError}
                setOpen={setLoginError}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 4,
                    py: 6,
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: '#ffffff'
                }}
            >
                {/* <Typography component="h1" variant="h5">
                    <AccountCircleTwoToneIcon />Login
                </Typography> */}

                <Typography variant="h5"><b>Welcome to Dovetail-crm!</b></Typography>
                <Typography variant="caption">Please sign in to continue</Typography>

                <form onSubmit={handleLogin}>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                        <Grid item xs={12} md={10} mb={1} mt={3}>
                            <TextField
                                size="small"
                                fullWidth
                                id="email"
                                label="Email/Username"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                autoFocus
                                onChange={handleChange}
                                error={!!validationErrors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                size="small"
                                fullWidth
                                name="password"
                                label="Password"
                                value={formData.password}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                onChange={handleChange}
                                error={!!validationErrors.password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>

                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}

                        <Grid item xs={12} md={10} my={1}>
                            <Button
                                type="submit"
                                fullWidth
                                size="small"
                                variant="contained"
                                startIcon={<LoginIcon sx={{ margin: '5px 10px' }} />}
                                sx={{ borderRadius: '20px' }}
                            >
                                Login
                            </Button>
                        </Grid>

                        {/* <Grid item>
                                <Link to='/register'>
                                    <Typography variant="body2" color='primary'>Don't have an account? Register</Typography>
                                </Link>
                            </Grid> */}
                        <Grid container item alignContent={'center'} justifyContent={'flex-end'}>
                            <Link to='/forgetPassword'>
                                <Typography variant="body2" color='primary'>Forgot Password</Typography>
                            </Link>
                        </Grid>

                    </Grid>
                </form>
            </Box >

        </>
    );
};
export default Login;