import React, { useState } from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Header from "../../Components/header/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Sidebar from "../../Components/sidebar/SideBar";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

import {
  Stack,
  Box,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import configServ from "../../services/config";

function ChangePassword() {
  //#region code
  const { encoded_data } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const token_data = encoded_data;
  const decode = jwtDecode(token_data);
  const userEmail = decode.data.email;
  const initialError = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const initialPasswords = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(initialError);
  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState(initialPasswords);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const isValidPassword = async (passwords) => {
    if (!passwords.currentPassword) {
      setErrors({ currentPassword: "Current password is required" });
      return false;
    }

    if (!passwords.newPassword) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }

    if (passwords.newPassword.length < 8) {
      setErrors({ newPassword: "New password must be at least 8 characters" });
      return false;
    }

    if (!passwords.confirmPassword) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }

    if (passwords.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Confirm password must be at least 8 characters",
      });
      return false;
    }

    // Password strength check
    // const hasUpperCase = /[A-Z]/.test(passwords.newPassword);
    // const hasLowerCase = /[a-z]/.test(passwords.newPassword);
    // const hasDigit = /\d/.test(passwords.newPassword);
    // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwords.newPassword);

    //   if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar)) {
    //     setErrors({
    //         newPassword: "New password must include at least one uppercase letter, one lowercase letter, one digit, and one special character"
    //     });
    //     return false;
    // }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrors({
        newPassword: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return false;
    }

    // Password is valid
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let cleanPassword = await isValidPassword(passwords);
      if (!cleanPassword) {
        return;
      } else {
        setErrors({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });

        console.log("Here");
        const data = {
          email: userEmail,
          current_password: passwords.currentPassword,
          new_password: passwords.newPassword,
          new_password_confirmation: passwords.confirmPassword,
        };
        const res = await configServ.resetAdminPassword(data);
        if (res.status === 200) {
          handleSuccess();
        } else {
          setErrors({
            currentPassword: "Error",
            newPassword: "Error",
            confirmPassword: "Error",
          });
          console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuccess = () => {
    setSuccess(true);
    setErrors(initialError);
    setPasswords(initialPasswords);
  };
  //#endregion

  return (
    <>

      <Box
        sx={{
          // marginTop:{
          //     xs:7,
          //     sm:8
          // },
          marginLeft: {
            xs: 7,
            sm: 8,
          },
          ...(open && { marginLeft: 33 }),
          transition: "200ms",
          minHeight: "77vh",
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Paper
            sx={{
              width: {
                md: "40%",
                sm: "60%",
                xs: "70",
              },
              padding: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              px: {
                md: "5%",
                sm: "5%",
              },
              my: 10,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: {
                  md: "large",
                  sm: "medium",
                  xs: "medium",
                },
              }}
            >
              Change Password
            </Typography>

            {success && (
              <Typography variant="body2" color="success">
                Password changed
              </Typography>
            )}
            <TextField
              required
              fullWidth
              label="Current Password"
              type="password"
              name="currentPassword"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={passwords.currentPassword}
              onChange={handleChange}
              error={!!errors.currentPassword}
              helperText={errors.currentPassword}
            />

            <TextField
              fullWidth
              label="New Password"
              type="password"
              name="newPassword"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={passwords.newPassword}
              onChange={handleChange}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />
            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={passwords.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />

            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Paper>
        </Stack>
      </Box>
      <Footer />

    </>
  );
}

export default ChangePassword;
