import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { CartItem } from "./CartItem";
import { assignCartAmount } from "../../../redux/reducers/Cart";

const CartDetails = ({ handleNext }) => {
    //#region code
    const { currency } = useSelector((state) => state.GlobalVariables);
    const dispatch = useDispatch()
    const { cartValue, cartAmount } = useSelector((state) => state.cart)
    const [amount, setAmount] = useState(0)

    const totalAmount = () => {
        let total = 0
        cartValue.map((item => {
            total += item.qty * item.rate
        }))
        // setAmount(total)
        dispatch(assignCartAmount(total))
    }
    useEffect(() => {
        totalAmount()
    }, [cartValue]);
    //#endregion

    return (
        <>
            {/* <Button onClick={abc}>ABC</Button> */}
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Stack sx={{
                    width: { xs: '100%', md: '50%' }
                }}
                    spacing={2}
                >
                    {cartValue.map((item) => {
                        return (
                            <CartItem key={item.id} data={item} />
                        )
                    })}
                </Stack>
                <Stack alignItems="left" sx={{ border: 'solid 1px #33333355', borderRadius: '2px', height: 'fit-content', width: { xs: '100%', md: '50%' }, px: "10px" }}>
                    <Typography variant='body'>Price Details ( <span style={{ fontWeight: 'bold' }}>{cartValue.length} item(s)</span> )</Typography>
                    <Stack direction='row' justifyContent="space-between">
                        <Typography variant='body2'>Total MRP</Typography>
                        <Typography variant='body2'>{currency} {cartAmount}</Typography>
                    </Stack>
                    <Divider sx={{ backgroundColor: 'black' }} />
                    <Stack direction='row' justifyContent="space-between">
                        <Typography variant='body'>Total Amount</Typography>
                        <Typography variant='body'>{currency}{cartAmount}</Typography>
                    </Stack>
                    <Stack alignItems="center">
                        <Button variant="contained" onClick={handleNext}
                            sx={{
                                mt: 3, mb: 2, width: "80%", minWidth: '20%', ":hover": { backgroundColor: 'black' }
                            }}
                        >
                            Place Order
                        </Button>
                    </Stack>
                </Stack>
            </Stack>


        </>
    )
}
export default CartDetails;