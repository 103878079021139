import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    IconButton,
    Typography,
    Stack,
    TextField
} from '@mui/material'
import configServ from '../../../../services/config';
import Checkbox from '@mui/joy/Checkbox';
import AlertDialog from "../../../../Components/AlertDialog/AlertDialog";


function LocationImage({ itemData, handleClose, open, editData, isEdited, setIsEdited }) {

    const [selectedImage, setSelectedImage] = useState('')
    const [imageToSend, setImageToSend] = useState('')
    const [formData, setFormData] = useState({
        status: true
    })
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
  
    const handleOpenDialog = (content) => {
      setDialogContent(content);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
      setDialogContent('');
    };
    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const checkHandler = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    //image to base64 converter
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const removeDataUrlPrefix = (base64String) => {
        return base64String.split(',')[1];
    };

    // handling onchange for file
    const handleChangeFile = async (e) => {
        const { name, files } = e.target
        const base64Image = await convertToBase64(files[0])
        setSelectedImage(removeDataUrlPrefix(base64Image))
        setImageToSend(base64Image)
        console.log(base64Image)
    }

    const handleUpload = async () => {
        const dataToSend = {
            ...formData,
            location_id: itemData.id,
            image: imageToSend,
        }
        console.log(dataToSend)

        try {
            if (!isEdit) {
                const result = await configServ.addLocationImg(dataToSend)
                console.log(result)
                if (result.success) {
                    setIsEdited(!isEdited)
                    clear()
                    handleOpenDialog('Image uploaded and data inserted successfully')
                }
            } else {
                const result = await configServ.editLocationImg(dataToSend)
                console.log(result)
                if (result.success) {
                    setIsEdited(!isEdited)
                    clear()
                    handleOpenDialog('Image Updated successfully')
                }
            }
        } catch (err) {
            console.log(err)
        }
    }


    const assignForEdit = async () => {
        if (editData !== undefined) {
            setFormData(editData)
            setSelectedImage(editData.image)
            setImageToSend(editData.image)
            setIsEdit(true)
            // console.log(editData)
            // alert('editData')
        }
    }

    useEffect(() => {
        assignForEdit()
    }, [editData])

    const clear = () => {
        setFormData({
            status: true
        });
        setSelectedImage('')
        setImageToSend('')
        // setIsEdit(false)
    }


    return (
        <>
                   <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5'>{isEdit ? 'Update' : 'Add'} Location Image</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={2}>
                        <Typography variant='body' color='text.secondary'>Choose image for {itemData.location_name}:</Typography>
                        <Stack
                            direction={{
                                xs:'column',
                                sm:'row'
                            }}
                            justifyContent='space-between'
                            spacing={10}
                        >
                            <Stack
                                spacing={2}
                                sx={{
                                    borderRadius: 2,
                                    border: '1px solid #33333333',
                                    padding: '2rem'
                                }}>
                                <TextField
                                    type='file'
                                    name='itemImage'
                                    onChange={handleChangeFile}
                                    size='small'
                                    file={formData.image}
                                />
                                <TextField
                                    label='Alt Text'
                                    size='small'
                                    name='alt'
                                    onChange={changeHandler}
                                    value={formData.alt}
                                />
                                <Checkbox
                                    label="Status"
                                    name='status'
                                    onChange={checkHandler}
                                    checked={Boolean(formData.status)}
                                />
                            </Stack>
                            <img
                                alt='Preview'
                                src={`data:image/png;base64,${selectedImage || ""}`}
                                style={{ marginRight: 30 }}
                                width={200}
                            />
                        </Stack>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { handleClose(); handleUpload() }} autoFocus variant='contained'>
                        {isEdit ? 'Update' : 'Upload'}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default LocationImage