import React, { memo, useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    NativeSelect,
    TextField,
    InputAdornment,
    Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import configServ from '../../../services/config';
import AlertDialog from '../../AlertDialog/AlertDialog';

const ConfigurationTable = ({ isChanged, handleEdit, pagination = true }) => {
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [count, setCount] = useState(0);
    const [mailConfigList, setMailConfigList] = useState([]);
    const [filterMailConfigList, setFilterMailConfigList] = useState([]);
    const [searchBy, setSearchBy] = useState('brand_name');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const headers = ['COMPANY', 'TO', 'CC', 'MESSAGE', 'STATUS', 'ACTION'];

    useEffect(() => {
        getMailConfigurations();
    }, [isChanged]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const paginatedData = pagination
        ? filterMailConfigList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filterMailConfigList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const getMailConfigurations = async () => {
        try {
            const data = {
                company_id: company_id
            }
            const res = await configServ.getMailConfigurations(data);
            if (res.status === 200) {
                setMailConfigList(res.data);
                setFilterMailConfigList(res.data);
                setCount(res.data.length)
            }
            else {
                setMailConfigList([]);
                setFilterMailConfigList([]);
                setCount(0)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let data = [];
            if (search === '') {
                data = mailConfigList;
            }
            else if (searchBy === 'CompanyName') {
                data = mailConfigList.filter(x => x.CompanyName.toLowerCase().includes(search.toLowerCase()))
            }
            setFilterMailConfigList(data);
            setCount(data.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            {/* search starts here */}
            {/* <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
                <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Configuration List :{count}</Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                        <FormControl
                            fullWidth
                        >
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Search By
                            </InputLabel>
                            <NativeSelect

                                onChange={handleSearchBy}
                                inputProps={{
                                    name: 'searchBy',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={'CompanyName'}>Company Name</option>
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        fullWidth
                        placeholder="search"
                        variant="outlined"
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fill: 'grey' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid> */}
            {/* search ends here */}

            <TableContainer component={Paper} sx={{ py: 2 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={headerCellStyle}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}
                                sx={{
                                    ...rowStyle,
                                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                }}
                            >
                                <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                <TableCell>{row.CompanyName}</TableCell>
                                <TableCell>{row.mail_to}</TableCell>
                                <TableCell>{row.mail_cc}</TableCell>
                                <TableCell>{row.message}</TableCell>
                                <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                <TableCell>
                                    <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                        onClick={() => { handleEdit(row); scrollToTop() }}
                                    >
                                        <Tooltip title='Edit' arrow>
                                            <EditIcon color='primary' />
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 1 * emptyRows }}>
                                <TableCell colSpan={headers.length + 2} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        </>
    );
};

export default memo(ConfigurationTable);
