import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import PopupDialogTitle from '../Dialog/PopupDialogTitle';

const UploadImage = ({open, handleClose, Img}) => {
    
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-list-image-title"
        aria-describedby="upload-list-image-description"
        fullWidth
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            width: 'auto',
            height: 'auto',
          },
        }}
      >
        <PopupDialogTitle handleClose={handleClose} show={false} />
        <br />
        <DialogContent>
        <img src={Img} alt='upload image' style={{ width: 100, height: 100, marginRight: 16 }} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UploadImage
