import React, { useState, useEffect } from 'react';
import {
    Stack,
    Typography,
    Box,
    CircularProgress,
    IconButton,
    Tooltip,
    Fade,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem, qtyChange } from '../../../redux/reducers/Cart';
import configServ from '../../../services/config';
import { Autocomplete } from '@mui/material';

//icon
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';


export const CartItem = ({ data }) => {
    //#region code
    const { currency } = useSelector((state) => state.GlobalVariables);
    const { company_type } = useSelector((state) => state.admin);
    const [productImage, setProductImage] = useState([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [item, setItem] = useState([]);
    const dispatch = useDispatch()
    const options = Array.from({ length: 1000 }, (_, index) => index + 1);

    const fetchItem = async () => {
        try {
            const result = await configServ.getitemdetail({ "id": data.id })
            // console.log('item',result)
            setItem(result)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchImage = async () => {
        setImageLoader(true)
        try {
            const result = await configServ.get_item_img({ "product_id": data.id })
            if (result.data.length !== 0) {
                result.data.map((item) => {
                    // setProductImage([...productImage,`data:image/png;base64,${item.image}`])
                    setProductImage((state) => ([
                        ...state,
                        `data:image/png;base64,${item.image}`
                    ]))
                })
                setImageLoader(false)
            } else {
                setProductImage(['https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image'])
                setImageLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchItem()
        fetchImage()
        // console.log(data)
    }, [])

    const handleQuantityChange = (e, value) => {
        // const dataToSend = {
        //     id: data.id,
        //     value: e.target.value
        // }
        const dataToSend = {
            id: data.id,
            value: value
        };
        dispatch(qtyChange(dataToSend))
    }
    //#endregion

    return (
        <Stack direction='row' spacing={2} alignItems="center" sx={{ border: 'solid 1px #33333355', borderRadius: '2px', height: { xs: '120px', md: '145px' }, width: { xs: '100%', md: '100%' }, px: '10px' }}>
            <Stack sx={{ width: { xs: '6rem', md: '6rem' } }}>
                {/* <Button onClick={abc}>abc</Button> */}
                <Box
                    sx={{
                        width: '100%',
                        aspectRatio: 1 / 1,
                        position: 'relative'
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            aspectRatio: 1 / 1,
                            borderRadius: '7px',
                        }}
                        alt="Product"
                        src={productImage[0]}
                    />
                    {imageLoader && (<Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#33333377',
                            borderRadius: '7px'
                        }}
                    >
                        <CircularProgress />
                    </Box>)}
                </Box>
            </Stack>
            <Stack width='80%'>
                <Typography
                    variant='h5'
                    sx={{
                        fontSize: 'medium',
                        fontWeight: 'bold',
                        color: '#333333dd'
                    }}
                >
                    {`${item[0]?.item_name} `}{data?.item_size !== undefined ? `(${data?.item_size})` : ''}
                </Typography>
                <Typography
                    variant='body'
                    sx={{
                        fontSize: 'small',
                        color: '#333333dd'
                    }}
                >
                    {item[0]?.description}
                </Typography>
                <Stack direction='row' justifyContent='space-between' >
                    <Stack direction='row' spacing={1}>
                        <Typography variant='body'>{currency}</Typography>
                        <Typography variant='body'>{data.qty * data.rate}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <FormControl
                            fullWidth
                            size='small'
                            variant='standard'
                            sx={{
                                minWidth: 80,
                            }}
                        >
                            {/* <InputLabel id="demo-simple-select-label">QTY</InputLabel>
                    <Select
                        // value={data.qty}
                        label="QTY"
                        onChange={handleQuantityChange}
                    >
                        {options.map((option) => (
                         <MenuItem key={option} value={option}>{option}</MenuItem>
                         ))}
                    </Select> */}
                            <Autocomplete
                                options={options}
                                value={data.qty}
                                getOptionLabel={(option) => String(option)}
                                renderInput={(params) => (
                                    <TextField {...params} label={company_type !== 'service' ? `QTY (${data.unit})` : `Validity (${data.unit})`} variant="standard" />
                                )}
                                onChange={handleQuantityChange}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
            </Stack>
            <Stack alignItems='flex-start' sx={{ height: '100%' }} >
                <Tooltip title='Remove Item' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton onClick={() => { dispatch(removeItem(data.id)) }}>
                        <CloseIcon fontSize='medium' color='black' />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    )
}
