import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Stack,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Container
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import configServ from '../../services/config';
import CatalogTable from './CatalogTable';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import CardHeadMenu from '../../Components/Card/CardHeadMenu/CardHeadMenu';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';

//FUNCTION
function Catalog() {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const [isCreate, setIsCreate] = useState(false);
    const [formData, setFormData] = useState({})
    const [catalogItem, setCatalogItem] = useState([{
        id: 1,
        item_id: '',
    }])
    const [itemList, setItemList] = useState([])
    const [applicationList, setApplicationList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCatalogItem = (id, event) => {
        const { name, value } = event.target;
        setCatalogItem((state) =>
            state.map((item) =>
                item.id === id ? { ...item, [name]: value } : item
            )
        );
        if (name === 'item_id') {
            const selectedItem = itemList.filter((item) => (value === item.id))
            setCatalogItem((state) =>
                state.map((item) =>
                    item.id === id ? { ...item, item_name: selectedItem[0].item_name } : item
                )
            );
        }
    };

    const handleAutoOnchangeForItem = (e, value) => {
        if (value) {
            const { name, id } = value
            const selectedItem = itemList.filter((item) => (id === item.id))
            setCatalogItem((state) =>
                state.map((item) =>
                    item.id === id ? { ...item, item_name: selectedItem[0].item_name } : item
                )
            );
        }
    }

    const handleAutoOnchange = (e, value) => {
        if (value) {
            const { name, label } = value
            setFormData((state) => ({
                ...state,
                [name]: label
            }))
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        if (!catalogItem[0].item_id) {
            handleOpenDialog('Warning', 'please select item', 'warning')
            return;
        }
        dispatch(triggerLoader());
        const dataToSend = {
            ...formData,
            "items": catalogItem,
            "company_id": company_id,
            "user_id": user_id
        }
        try {
            if (!isEdit) {
                const result = await configServ.createCatalog(dataToSend);
                if (result.success) {
                    handleOpenDialog('Success', result.message, 'success');
                    clear();
                    setIsChanged(!isChanged);
                }
                else {
                    handleOpenDialog('Error', 'Failed to create catalog.', 'error');
                }
            }
            else {
                const result = await configServ.editCatalog(dataToSend);
                if (result.success) {
                    handleOpenDialog('Success', result.message, 'success');
                    clear();
                    setIsChanged(!isChanged);
                }
                else {
                    handleOpenDialog('Error', 'Failed to update catalog.', 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const fetchItemList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    const result = res.data.filter(x => x.status === 1);
                    setItemList(result);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const setItemToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setItemList((state) => ([
                ...state,
                {
                    // ...item,
                    label: item.item_name,
                    name: 'item_id',
                }
            ]))
        })
    }

    const fetchApplication = async () => {
        try {
            const result = await configServ.getApplication();
            setApplicationToList(result.data);
        }
        catch (err) {
            console.log(err)
        }
    }

    const setApplicationToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setApplicationList((state) => ([
                ...state,
                {
                    // ...item,
                    label: item.name,
                    name: 'application_name',
                }
            ]))
        })
    }

    useEffect(() => {
        fetchItemList();
        fetchApplication();
    }, []);

    const addCatalogItem = () => {
        setCatalogItem((state) => ([
            ...state,
            {
                id: catalogItem[catalogItem.length - 1].id + 1,
                item_id: '',
            }
        ]))
    }

    const delAssociatedItem = (id) => {
        const updatedItemList = catalogItem.filter((item) => item.id !== id);
        setCatalogItem(updatedItemList);
    };

    const clear = () => {
        setFormData({})
        setCatalogItem([{
            id: 1,
            item_id: '',
        }])
        setIsEdit(false);
    }

    const handleEditData = (data) => {
        setIsEdit(true);
        setIsCreate(true);
        setFormData(data);
        setCatalogItem(data.catalog_items);
    }

    const handleCreatePopup = () => {
        setIsCreate(!isCreate);
    }

    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <Container>
                <CardHeadMenu
                    prefix={"Na"}
                    suffix={"Na"}
                    title={"CATALOG"}
                    handlePopup={handleCreatePopup}
                    isEdit={isEdit}
                    create={isCreate}
                />
            </Container>
            {
                isCreate &&
                <Paper
                    elevation={0}
                    sx={{
                        width: {
                            md: '80%',
                            sm: '90%',
                            xs: '100%'
                        },
                        padding: 4,
                        border: '1px solid #55555522',
                        backgroundColor: "#e3f3fc"
                    }}
                >
                    <Stack
                        direction={'column'}
                        spacing={1}
                    >
                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'row'
                            }}
                            spacing={2}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label='Catalog Name'
                                name='catalog_name'
                                sx={{
                                    width: {
                                        md: '50%',
                                        sm: '100%'
                                    },
                                    backgroundColor: 'white'
                                }}
                                value={formData.catalog_name || ''}
                                onChange={changeHandler}
                            />

                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={formData.application_name || ''}
                                    label="Application"
                                    name='application_name'
                                    onChange={changeHandler}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'dcrm'}>DCRM</MenuItem>
                                    <MenuItem value={'noroom'}>NoRoom</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                        >
                            <FormControlLabel
                                control={<Checkbox />}
                                name='currentlyActive'
                                checked={Boolean(formData.currentlyActive)}
                                onChange={handleChangeCheckbox}
                                label='Currently Active'
                            />
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 3, background: '#55555533' }} />
                    <Box
                        sx={{
                            borderRadius: 2,
                            border: '1px solid #33333322',
                        }}
                    >
                        {
                            catalogItem.map((item, index) => (
                                <Stack
                                    key={item.id}
                                    direction={{
                                        xs: 'column',
                                        sm: 'row'
                                    }}
                                    width='100%'
                                    justifyContent='space-between'
                                    spacing={2}
                                    padding={2}
                                >
                                    <FormControl size='small' sx={{ width: { xs: '100%', sm: '80%' } }} >
                                        <InputLabel>Item</InputLabel>
                                        <Select
                                            label='Item '
                                            sx={{
                                                width: '100%',
                                                backgroundColor: 'white'
                                            }}
                                            name="item_id"
                                            onChange={(e) => { handleChangeCatalogItem(item.id, e) }}
                                            value={item.item_id}
                                        >
                                            {
                                                itemList.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>{item.item_name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <Stack direction='row' spacing={2}>
                                        {catalogItem.length > 1 && (
                                            <Tooltip title='Remove' arrow>
                                                <Button
                                                    color="error"
                                                    size="sm"
                                                    variant="outlined"
                                                    onClick={() => { delAssociatedItem(item.id) }}
                                                    sx={{
                                                        flex: 1
                                                    }}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Tooltip title='Add' arrow>
                                            <Button
                                                color="info"
                                                onClick={addCatalogItem}
                                                size="sm"
                                                variant="outlined"
                                                sx={{
                                                    flex: 1
                                                }}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            ))
                        }
                    </Box>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                        padding={1}
                        mt={2}
                    >
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            width='100%'
                            justifyContent={{
                                xs: 'center',
                                sm: 'flex-end'
                            }}
                        >
                            <Button
                                variant='contained'
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '200px'
                                    },
                                    m: 1,
                                }}
                                color='secondary'
                                title='Reset'
                                onClick={clear}
                                startIcon={<RotateLeftIcon />}
                            >
                                Reset
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '200px'
                                    },
                                    m: 1
                                }}
                                color={isEdit ? 'success' : 'primary'}
                                startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                onClick={handleSubmit}
                            >
                                {isEdit ? 'Update' : 'Save'}
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            }
            <Container>
                <CatalogTable handleEditData={handleEditData} isChanged={isChanged} />
            </Container>
        </>
    )
}

export default Catalog;