import React, { memo, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import configServ from '../../../services/config';
import AlertDialog from '../../AlertDialog/AlertDialog';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';

//FUNCTION
const SalesOrderTable = ({ isChanged, handleEditData, pagination = true }) => {
  const dispatch = useDispatch();
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState('partyname');

  const [orderList, setOrderList] = useState([]);
  const [orderDataList, setOrderDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const headers = ['CUSTOMER NAME', 'ORDER NO.', 'ORDER DATE', 'TOTAL AMOUNT', 'ACTION'];

  useEffect(() => {
    fetchSalesOrderList();
  }, [isChanged]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? orderDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : orderDataList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5',
    color: 'white',
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  const fetchSalesOrderList = async () => {
    try {
      setLoading(true);
      const res = await configServ.salesOrderList(
        {
          'company_id': company_id
        }
      );
      // const result = res.map((item, index) => ({
      //   id: item.id,
      //   customer_id: item.customer_id,
      //   partyname: item.partyname,
      //   billtoaddress: item.billtoaddress,
      //   shiptoaddress: item.shiptoaddress,
      //   order_no: item.order_no,
      //   order_date: CheckAndReturn.transformToDate2(item.order_date).toString() || 'N/A',
      //   order_type: item.order_type,
      //   po_number: item.po_number || 'N/A',
      //   po_date: CheckAndReturn.transformToDate2(item.po_date).toString() || 'N/A',
      //   validitydate: CheckAndReturn.transformToDate2(item.validitydate).toString() || 'N/A',
      //   total_amount: Number(item.total_amount),
      //   status: item.isDeleted,
      //   items: item.items,
      // }));
      setOrderDataList(res || [])
      setOrderList(res || []);
      setCount(res.length);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === '') {
        data = orderList;
      }
      else if (searchBy === 'partyname') {
        data = orderList.filter(x => x.partyname.toLowerCase().includes(search.toLowerCase()));
      }
      setOrderDataList(data);
      setCount(data.length);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handlePrint = (params) => {
    printOrder(params)
  }

  const printOrder = async (order_id) => {
    const data = {
      "order_id": order_id,
    }
    dispatch(triggerLoader())
    try {
      const result = await configServ.printSalesOrder(data);
      const blobFile = new Blob([result], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blobFile);
      window.open(url);
    }
    catch (err) {
      console.log(err);
      handleOpenDialog('Print failed');
    }
    finally {
      dispatch(triggerLoader())
    }
  }

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    )
  }
  else {
    return (
      <>
        <AlertDialog
          open={dialogOpen}
          setOpen={handleCloseDialog}
          title="Alert"
          message={dialogContent}
          buttonTitle="Ok"
        />

        {/* search starts here */}
        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ pt: 1 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} ml={0} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Sales Order List :{count}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
              <FormControl
                fullWidth
              >
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  onChange={handleSearchBy}
                  inputProps={{
                    name: 'searchBy',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'partyname'}>Customer</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: 'grey' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* search ends here */}

        <TableContainer component={Paper} sx={{ py: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, rowIndex) => (
                <TableRow key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell>{row.partyname}</TableCell>
                  <TableCell>{row.order_no}</TableCell>
                  <TableCell>{CheckAndReturn.transformToDate2(row.order_date).toString() || 'N/A'}</TableCell>
                  <TableCell>₹{row.total_amount}</TableCell>
                  {/* <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell> */}
                  <TableCell>
                    <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5 }}

                    >
                      <Tooltip title="Print" placement="top">
                        <IconButton onClick={() => handlePrint(row.id)}>
                          <PrintTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Edit' arrow>
                        <IconButton onClick={() => handleEditData(row)}>
                          <EditIcon color='primary' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </>
    );
  }
};

export default memo(SalesOrderTable);
